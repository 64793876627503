.view-degree {
    max-width: 85rem;
    margin: auto;
    width: 100%;
    padding-bottom: 3rem;

    i {
        margin-top: -.2rem;
    }

    > header {
        border-radius: .5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        background: var(--primary-color);
        background: linear-gradient(180deg,
        var(--primary-color) 0%,
        var(--primary-color) 35%,
        var(--darken-15-primary-color) 100%);

        @include box-shadow(
        10px 10px 10px var(--shadow-secondary-color),
        -5px -5px 10px var(--shadow-secondary-color));

        h2 {
            padding-bottom: 10px;
            z-index: 5;

            color: var(--white-color);
            border-bottom: 2px dashed var(--secondary-color);
            text-shadow: 1px 1px var(--shadow-2-inverse-color);
        }

        .-send-file {
            position: absolute;
            left: 10px;
            bottom: 10px;

            font-size: 1rem;
            border: none;
            transition: all .5s ease;

            z-index: 5;

            background-color: var(--lighten-30-secondary-color);
            color: var(--primary-color);

            @include box-shadow(
            10px 10px 10px var(--shadow-secondary-color),
            -5px -5px 10px var(--shadow-secondary-color));

            &:hover {
                background-color: var(--primary-color);
                color: var(--white-color);
            }
        }

        .-background {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            z-index: 3;

            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            .overlay {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                opacity: .4;

                background: var(--black-color);
                background: linear-gradient(180deg,
                var(--darken-10-secondary-color) 0%,
                var(--darken-20-inverse-color) 35%,
                var(--darken-30-inverse-color) 100%);
            }

            border-radius: .5rem;

            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }

    > main {
        display: flex;
        flex-wrap: wrap;

        .no-access-wrapper {
            flex: 1;

            > div {
                flex: 1;
                padding: 1rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
                margin-right: 1rem;
                border-radius: .5rem;

                @include box-shadow(
                10px 10px 25px var(--shadow-secondary-color),
                -5px -5px 25px var(--shadow-secondary-color));


                h2 {
                    padding: 0px;
                    margin: 0px;
                    font-size: 1.5rem;
                    font-weight: normal;
                }

                .-message {
                    padding: 1rem;
                    display: flex;
                    flex-direction: row;

                    align-items: center;
                    justify-content: flex-start;

                    i {
                        margin: 1rem;
                        font-size: 2.5rem;
                        color: var(--primary-color);
                    }
                }
            }
        }

        .modules {
            padding-right: 1rem;
            flex: 1;

            .module {
                width: 100%;
                padding: 1rem;
                margin-top: 1rem;

                @include box-shadow(
                10px 10px 25px var(--shadow-secondary-color),
                -5px -5px 25px var(--shadow-secondary-color));


                header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    i {
                        font-size: 1.5rem;
                        cursor: pointer;

                        color: var(--primary-color);

                        &.-dragger {
                            color: var(--inverse-color);
                        }
                    }

                    h4 {
                        padding: 0px;
                        margin: 0px;
                        font-weight: normal;
                        flex: 1;
                        font-size: 1.5rem;
                        margin-left: .5rem;
                    }
                }
            }

            .add-module {
                margin-top: 1rem;
            }

            .add-module, .add-content {
                cursor: pointer;
                width: 100%;
                transition: all .5s ease;

                background: none;
                text-align: center;
                padding: 1rem;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: .5rem;

                color: var(--inverse-color);
                border: 2px dashed var(--secondary-color);

                &:hover, &:active {
                    border: 2px dashed var(--highlight);
                    background: var(--lighten-40-highlight) !important;
                    color: var(--font-color) !important;
                }

                i {
                    margin-right: .5rem;
                }
            }

            .content-list {
                .-content {
                    display: flex;
                    flex-direction: row;
                    padding: .7rem .5rem;
                    justify-content: center;
                    align-items: center;

                    border-radius: .5rem;
                    margin-top: .5rem;

                    background-color: var(--shadow-4-secondary-color);

                    a {
                        flex: 1;
                        text-align: left;
                        font-size: 1.2rem;
                    }

                    span {
                        margin-left: .5rem;
                        margin-top: .1rem;

                        color: var(--inverse-color);
                    }

                    i {
                        font-size: 1.5rem;
                        margin-left: .7rem;

                        color: var(--inverse-color);

                        &.-publish, &.-done {
                            color: var(--success-color);
                        }

                        &.-not-done {
                            color: var(--lighten-15-inverse-color);
                        }
                    }
                }
            }

            .add-content {
                padding: 1rem;
                margin-top: 1rem;
                justify-content: flex-start;
            }

            min-height: 60vh;
            min-width: 42rem;
        }

        .side {
            position: sticky;
            top: -.5rem;
            height: 1px;

            min-width: 25rem;
            max-width: 26rem;

            .-description {
                flex: 1;
                padding: 1rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
                border-radius: .5rem;

                @include box-shadow(
                10px 10px 25px var(--shadow-inverse-color),
                -5px -5px 25px var(--shadow-inverse-color));

                h4 {
                    padding: 0px;
                    margin: 0px;
                    font-size: 1.5rem;
                    font-weight: normal;
                }

                .-actions {
                    margin-top: 1rem;
                    .dripping-opts {
                        margin-top: 1rem;
                        margin-left: .5rem;
                    }
                }
            }

            .-notifications {
                .-draft {
                    margin-bottom: 1rem;
                    width: 100%;
                    padding: 1.2rem;
                    align-items: center;
                    justify-content: center;
                    border-radius: .5rem;
                    font-size: 1rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border: 1px solid var(--lighten-20-secondary-color);
                    color: var(--inverse-color);
                }
            }

            .-links {
                margin-top: 1rem;
                a { float: right; color: var(--inverse-color); }
            }

            .-buttons {
                button {
                    padding: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i {
                        margin-top: -.2rem;
                    }
                }

            }

            button.btn {
                width: 100%;
            }
        }
    }

    .degree-certificate {
        background-color: var(--lighten-50-inverse-color);
        color: var(--primary-color);
        text-shadow: 1px 1px var(--shadow-3-inverse-color);

        @include box-shadow(
        10px 10px 10px var(--shadow-2-inverse-color),
        -5px -5px 10px var(--shadow-2-inverse-color));
    }

    .degree-done, .-published {
        background-color: var(--success-color);
        color: var(--white-color);
        text-shadow: 1px 1px rgba(var(--darken-20-success-color), .4);

        @include box-shadow(
        10px 10px 10px var(--shadow-inverse-color),
        -5px -5px 10px var(--shadow-inverse-color));
    }


    .degree-done, .-published, .degree-certificate {
        font-size: 1.5rem;
        border-radius: .5rem;
        padding: 1.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        i {
            margin-left: .5rem;
        }
    }

    .-published {
        font-size: 1.2rem;
    }

    .magic-input {
        color: var(--darken-10-inverse-color);

        span.-editable {
            cursor: pointer;
            text-align: right;

            color: var(--black-color);
            border-bottom: 1px dashed var(--primary-color);
        }

        &.-link {
            p {
                color: var(--black-color);
            }
        }
    }

    .ql-container {
        p {
            font-size: 1rem;
        }
    }

    .add-content.-remove,
    .add-module.-remove {
        transition: all 0s ease !important;
        border-color: var(--error-color) !important;
    }

    padding-bottom: 3rem;
}

.progress-wrapper {
    border-top: 1px solid var(--lighten-17-inverse-color);

    margin-top: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .label {
        width: 50px;
        text-align: right;
        font-size: 1.3rem;
        color: var(--darken-10-success-color);
    }

    .progress-bar-wrapper {
        flex: 1;
    }

    .progress-bar {
        width: 100%;
        padding: .1rem;
        border-radius: .5rem;

        background-color: var(--lighten-22-inverse-color);
        box-shadow: inset 0 1px 3px var(--shadow-3-inverse-color);
    }

    .progress-bar-fill {
        display: block;
        height: .6rem;
        border-radius: .5rem;

        transition: width 500ms ease-in-out;
        background-color: var(--success-color);
    }
}

.magic-input {
    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    span.-editable {
        display: inline-block;
        position: relative;
    }
    ul.-dropdown {
        display: none;
        background-color: lighten($background, 70);

        @include box-shadow(
            10px 10px 10px var(--shadow-inverse-color),
            -5px -5px 10px var(--shadow-inverse-color));

        &.-open { display: block; }

        z-index: 10;
        list-style: none;
        margin: 0px;
        padding: 0px;

        position: absolute;

        top: 90%;
        left: 0px;

        width: 100%;
        min-width: 100px;;
        max-width: 100px;
        border-radius: .5rem;

        li {
            padding-left: .6rem;
            padding-right: .6rem;
            padding-top: .6rem;
            padding-bottom: .4rem;
            border-radius: .5rem;

            &:hover {
                color: var(--primary-color);
                background-color: var(--lighten-15-inverse-color);
            }

            a {
                transition: color .7s ease;

                align-items: center;
                position: relative;
                display: flex;
                text-decoration: none;
                color: var(--darken-33-primary-color);

                &:hover {
                    color: var(--darken-33-primary-color);
                }

                i {
                    font-size: 1.3rem;
                    margin-right: .5rem;
                    margin-left: .2rem;
                }
            }
        }
    }
}
