.view-admin-bank {
    button {
        align-items: center;
        justify-content: center;
    }
    .deposit-values-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
        flex-wrap: wrap;

        .-option {
            padding: 1rem;
            margin-right: 1rem;
            min-width: 18rem;

            @include box-shadow(
            10px 10px 25px var(--shadow-inverse-color),
            -5px -5px 25px var(--shadow-inverse-color));

            main {
                text-align: center;
                .label {
                    font-size: 1rem;
                    &.-pre { margin-bottom: -.5rem; }
                    &.-after { margin-top: -.5rem; }
                }

                .value {
                    font-size: 2.5rem;
                }

                margin-bottom: .5rem;
            }

            footer {
                text-align: center;
                font-size: 1.5rem;
                border-top: 1px solid var(--lighten-20-inverse-color);
                p {
                    padding: 1rem;
                    color: var(--darken-10-success-color);
                    font-weight: bold;
                }

                button {
                    width: 100%;
                    font-weight: normal;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.paid-wrapper {
    padding: 1rem;
    margin: auto;
    max-width: 600px;

    @include box-shadow(
    10px 10px 25px var(--shadow-inverse-color),
    -5px -5px 25px var(--shadow-inverse-color));

    header {
        display: flex;
        flex-direction: row;

        i {
            font-size: 2rem;
            color: var(--success-color);
        }

        h2 {
            margin-top: .2rem;
            margin-left: .5rem;
            border-bottom: 0px;
        }
    }

    main {
        padding: 1rem;
        button {
            margin-top: 1rem !important;
        }

        p {
            color: var(--inverse-color);
        }
    }
}

.checkout-wrapper .card-widget-wrapper {
    > header {
        display: flex;
        flex-direction: row;

        h2 {
            border-bottom: 0px;
        }

        img {
            margin-left: 2rem;
            height: 25px;
        }
    }

    .field {
        background: white;
        i {
            color: #2a72ee;
        }
    }

    > main {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        .card-form-wrapper {
            min-width: 550px;
        }

        .plans-wrapper {
            max-width: 22rem;
            margin-left: 1rem;
            padding-top: 0px;
            margin-top: -4rem;
        }
    }
}

.payment-methods-wrapper {
    min-width: 550px;

    .add-card {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem !important;
        padding: 1rem;
    }

    .payment-method {
        margin-bottom: 1rem;

        padding: .2rem;
        border-radius: .2rem;

        display: flex;
        flex-direction: row;

        @include box-shadow(
        5px 5px 12px var(--shadow-inverse-color),
        -2px -2px 12px var(--shadow-inverse-color));

        &:hover {
            @include box-shadow(
            5px 5px 12px var(--shadow-3-highlight),
            -2px -2px 12px var(--shadow-3-highlight));
        }

        .-brand {
            padding: .5rem;
            align-items: center;
            justify-content: center;
            display: flex;

            img {
                height: 30px;
            }
        }

        .-number {
            display: flex;
            align-items: center;
            flex: 1;

            padding: .5rem;
            p {
                font-size: 1rem;
                height: 1rem;
                color: var(--darken-15-inverse-color);
            }
        }
    }
}
