%buttons, button.btn {
    -webkit-appearance: none;
    display: inline-block;
    padding: 0.75rem 1.25rem;
    margin: 0 0 0 0;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
}
%buttons-hover, button.btn:hover {
    text-decoration: none;
}
%buttons-focus, button:focus {
    text-decoration: none;
}
#{$buttons} {
    @extend %buttons;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:hover {
        @extend %buttons-hover;
    }

    &:focus,
    &:active {
        @extend %buttons-focus;
    }
}

%buttons, button.btn {
    border: $button-border-width $button-border-style $button-border-color;
    border-radius: $button-border-radius;
    background: $button-background;
    color: $button-color;
    font-weight: normal;
    font-family: $button-font-family;
    font-size: $button-font-size;
    text-transform: $button-text-transform;
}

%buttons-hover, button.btn:hover {
    border: $button-border-width $button-border-style var(--darken-20-primary-color);
    color: $button-color;
}

%buttons-focus, button:focus {
    border: $button-border-width $button-border-style var(--darken-20-primary-color);
    color: $button-color;
}

#{$buttons} {
    @extend %buttons;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:hover {
        @extend %buttons-hover;
    }

    &:focus,
    &:active {
        @extend %buttons-focus;
    }
}

.accent-button,
a.accent-button {
    color: $accent-button-color;
    border: 1px solid $accent-button-background;
    background: $accent-button-background;

    &:hover,
    &:focus,
    &:active {
        color: $accent-button-color-hover;
        border: 1px solid var(--darken-20-primary-color);
        background: var(--darken-20-primary-color);
    }
}

.muted-button,
a.muted-button {
    background: $muted-background;
    border: $muted-border;
    color: $muted-color;

    &:hover,
    &:focus,
    &:active {
        color: $muted-color-hover;
        border: $muted-border-hover;
        background: $muted-background-hover;
    }
}

button.btn {
    display: inline-flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .4rem;
    font-weight: normal !important;
    font-size: 1.2rem;

    line-height: 1.2rem !important;

    i { margin-right: .7rem; }

    &.-success {
        background-color: var(--success-color);
        border-color: var(--success-color);
    }

    &.-danger {
        background-color: var(--primary-red);
        border-color: var(--primary-red);
    }

    &.-remove, &.-error {
        background-color: var(--primary-red);
        border-color: var(--primary-red);
        &:hover, &:active {
            border-color: var(--darken-red);
            background-color: var(--darken-red);
        }
    }

    &.-secondary {
        background-color: var(--lighten-20-secondary-color);
        border-color: var(--lighten-30-secondary-color);
        color: var(--darken-10-secondary-color);

        &:hover {
            background-color: var(--lighten-20-secondary-color);
            border-color: var(--primary-color);
            color: var(--primary-color);
        }

        &.-inactive {
            background-color: var(--background-color);
            border: 3px solid var(--secondary-color);
            color: var(--darken-10-secondary-color)
        }

        i.-rounded {
            border-radius: 1rem;
            background: var(--primary-color);
            color: var(--secondary-color);
        }
    }
}