.theme.-baby,
.theme.-dark-baby {
    --primary-red: #ff2600;
    --darken-red: #c4270c;

    /* primary color */
    --primary-color: #CAB1BD;

    --box-shadow-primary-color: rgba(201, 174, 187, .2);

    --lighten-10-primary-color: #d3bbc6;
    --lighten-15-primary-color: #e0c4d1;
    --lighten-20-primary-color: #e7c8d7;

    --darken-2-primary-color: #bba1ae;
    --darken-5-primary-color: #b499a7;
    --darken-8-primary-color: #ad93a0;
    --darken-10-primary-color: #927b87;
    --darken-15-primary-color: #977d8b;
    --darken-20-primary-color: #836b77;
    --darken-25-primary-color: #705965;
    --darken-33-primary-color: #584650;

    /* secondary-color */
    --secondary-color: #C4C2C0;
    --lighten-10-secondary-color: rgb(220, 220, 220);
    --lighten-15-secondary-color: rgb(230, 230, 230);
    --lighten-20-secondary-color: rgb(235, 235, 235);
    --lighten-21-secondary-color: rgb(235, 235, 235);
    --lighten-22-secondary-color: rgb(235, 235, 235);
    --lighten-23-secondary-color: rgb(235, 235, 235);
    --lighten-25-secondary-color: rgb(235, 235, 235);
    --lighten-30-secondary-color: rgb(240, 240, 240);
    --lighten-35-secondary-color: rgb(240, 240, 240);

    --darken-10-secondary-color: rgb(54, 54, 54);
    --darken-35-secondary-color: #353535;
    --darken-40-secondary-color: #2e2c2c;
    --darken-90-secondary-color: rgb(48, 47, 47);

    --accent-color: #e7edf8;
    --darken-30-accent-color: #e7edf8;
    --darken-50-accent-color: #e7edf8;

    --alternate-background: #fafafa;
    --alternate-color: #ff6d00;

    --link-color: var(--primary-color);
    --link-color-hover: var(--darken-25-primary-color);
    --lighten-5-link-color: var(--primary-color);

    --highlight: #ffbf00;

    --lighten-5-highlight: #ecb81a;
    --lighten-10-highlight: #e7b82b;
    --lighten-30-highlight: #d1a82d;
    --lighten-35-highlight: #f5c73c;
    --lighten-40-highlight: #fcdb7a;
    --lighten-42-highlight: #f7e3a9;
    --lighten-45-highlight: #fae8b1;
    --lighten-48-highlight: #eee0b7;

    --darken-30-highlight: #a07904;

    --error-color: #ff6d00;

    --lighten-10-error: #ff6d00;
    --lighten-30-error: #ff6d00;
    --darken-10-error: #ff6d00;

    --success-color: #00d97d;
    --darken-5-success-color: #03be70;
    --darken-10-success-color: #05af68;
    --darken-20-success-color: #019e5d;
    --darken-25-success-color: #018f54;

    --lighten-15-success-color: #0ddf87;
    --lighten-25-success-color: #14eb91;
    --lighten-30-success-color: #21f59d;

    --inverse-color: var(--secondary-color);

    --darken-10-inverse-color: var(--secondary-color);
    --darken-15-inverse-color: var(--secondary-color);
    --darken-20-inverse-color: var(--secondary-color);
    --darken-25-inverse-color: var(--secondary-color);
    --darken-30-inverse-color: var(--secondary-color);
    --darken-40-inverse-color: var(--secondary-color);

    --lighten-10-inverse-color: var(--lighten-10-secondary-color);
    --lighten-15-inverse-color: var(--lighten-15-secondary-color);
    --lighten-17-inverse-color: var(--lighten-15-secondary-color);
    --lighten-20-inverse-color: var(--lighten-20-secondary-color);
    --lighten-21-inverse-color: var(--lighten-20-secondary-color);
    --lighten-22-inverse-color: var(--lighten-20-secondary-color);
    --lighten-25-inverse-color: var(--lighten-25-secondary-color);
    --lighten-26-inverse-color: var(--lighten-25-secondary-color);
    --lighten-30-inverse-color: var(--lighten-30-secondary-color);
    --lighten-50-inverse-color: var(--lighten-35-secondary-color);

    --border-color: var(--primary-color);
    --font-color: #222;

    --shadow-primary-color: rgba(202, 177, 189, .2);

    --shadow-secondary-color: rgba(200, 200, 200, .2);
    --shadow-2-secondary-color: rgba(210, 210, 210, .3);
    --shadow-3-secondary-color: rgba(230, 230, 230, .4);

    --shadow-inverse-color: var(--shadow-secondary-color);
    --shadow-2-inverse-color: var(--shadow-2-secondary-color);
    --shadow-3-inverse-color: var(--shadow-3-secondary-color);

    --shadow-highlight: rgba(255, 191, 0, .2);
    --shadow-2-highlight: rgba(255, 191, 0, .2);
    --shadow-3-highlight: rgba(255, 191, 0, .1);
    --shadow-error: rgba(255, 109, 0, .3);
    --shadow-success-color: rgba(0, 217, 125, .3);

    --white-color: #fff;
    --background-color: #fff;
    --lighten-background-color: rgb(240, 240, 240);
    --darken-background-color: rgb(221, 221, 221);
    --shadow-background-color: rgba(221, 221, 221, .2);
    --black-color: #444;
}

.theme.-dark-baby {
    --white-color: #222;
    --black-color: #fff;
    --secondary-color: #444;
    --inverse-color: #444;
    --background-color: #333;
    --font-color: #FFF;

    --secondary-color: #777;
    --inverse-color: #B4B2B0;

    --lighten-10-secondary-color: rgb(20, 20, 20);
    --lighten-15-secondary-color: rgb(20, 20, 20);
    --lighten-20-secondary-color: rgb(35, 35, 35);
    --lighten-21-secondary-color: rgb(35, 35, 35);
    --lighten-22-secondary-color: rgb(35, 35, 35);
    --lighten-23-secondary-color: rgb(35, 35, 35);
    --lighten-25-secondary-color: rgb(35, 35, 35);
    --lighten-30-secondary-color: rgb(40, 40, 40);
    --lighten-35-secondary-color: rgb(40, 40, 40);
    --darken-10-secondary-color: rgb(140, 140, 140);

    --lighten-background-color: rgb(40, 40, 40);

    --shadow-secondary-color: rgba(20, 20, 20, .2);
    --shadow-2-secondary-color: rgba(30, 30, 30, .3);
    --shadow-3-secondary-color: rgba(100, 100, 100, .4);

    --shadow-inverse-color: var(--shadow-secondary-color);
    --shadow-2-inverse-color: var(--shadow-2-secondary-color);
    --shadow-3-inverse-color: var(--shadow-3-secondary-color);

    --darken-33-primary-color: #ac7c95;

    --highlight: #e7cb76;
    --lighten-35-highlight: #e7cb76;
}