.view-config {
    padding-bottom: 3rem;

    .how-to {
        padding-top: 1rem;

        p {
            color: var(--inverse-color)
        }

        .-cname {
            padding: .5rem;
            border-radius: .5rem;
            text-align: center;
            font-size: 1.5rem;
            font-weight: normal;

            background-color: var(--lighten-40-highlight);
            border: 1px solid var(--highlight);
        }
    }

    .img-uploader-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .img-uploader-wrapper .-default {
        max-width: 17rem;
        position: relative;
        margin-right: 1rem;
    }

    .-preview {
        flex: 1;
        text-align: left;
        min-width: 19rem;

        > div {
            margin-bottom: 2rem;
            padding-bottom: 1rem;

            border-bottom: 1px solid var(--lighten-15-secondary-color);
        }

        h5 {
            margin-left: 0px;
        }

        .-email-footer {
            display: flex;
            margin-top: 1rem;
            padding: 1rem;
            border-radius: .5rem;
            background-color: #FFF;
            border: 1px solid var(--lighten-15-inverse-color);

            .-avatar {
                max-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .-text p {
                text-align:left;
                font-family:Helvetica,Arial,sans-serif;
                font-size:15px;
                margin-bottom:0;
                color:#5F5F5F;
                line-height:135%;
            }
        }
    }

    .-remove {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .payment-method {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
        border-bottom: 1px solid var(--lighten-15-secondary-color);
    }
}
