$x-small: 600px !default;
$small: 800px !default;
$medium: 1000px !default;
$large: 1200px !default;

$mobile: $x-small !default;
$tablet: $small !default;
$desktop: $medium !default;

$font-size: .85rem !default;
$body-font-size: 1rem !default;

$font-style: normal !default;
$font-variant: normal !default;
$font-weight: normal !default;
$font-family: Josefin Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$line-height: 1.6 !default;

$heading-font-weight: 600 !default;
$heading-font-family: Josefin Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial,
  sans-serif !default;
$heading-line-height: 1.2 !default;

$h1-mobile: 1.75rem !default;
$h2-mobile: 1.5rem !default;
$h3-mobile: 1.25rem !default;
$h4-mobile: 1.1rem !default;
$h5-mobile: 1rem !default;

$h1: 2.25rem !default;
$h2: 2rem !default;
$h3: 1.75rem !default;
$h4: 1.5rem !default;
$h5: 1.25rem !default;

$padding: 1rem !default;
$margins: 1.5rem !default;
$content-padding: 60px 0 !default;
$content-padding-mobile: 30px 0 !default;

$border-width: 1px !default;
$border-style: solid !default;
$border-radius: 4px !default;

$button-font-weight: 600 !default;
$button-font-family: Josefin Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$button-font-size: 1rem !default;
$button-border-width: 1px !default;
$button-border-style: solid !default;
$button-text-transform: none !default;
$forms: (
  '[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email],        [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea'
) !default;
$buttons: ('button.btn, .btn') !default;

$round-buttons: 40px !default;

$code-size: 14px !default;
$code-family: Menlo, monospace !default;
$code-background: transparent !default;

$black: #000 !default;
$background: white !default;
$white: #FFF !default;

$heading-font-color: #404040 !default;
$border-color: #dedede !default;

$button-color: #ffffff !default;
$accent-button-color: #ffffff !default;
$accent-button-color-hover: #ffffff !default;

$stripes: #f8f8f8 !default;
$caption: #ababab !default;

$button-background: var(--primary-color) !default;
$button-background-hover: var(--darken-10-primary-color) !default;
$borders: $border-width $border-style var(--border-color) !default;

$button-border-color: $button-background !default;
$button-border-radius: $border-radius !default;
$accent-button-background: var(--secondary-color) !default;

$muted-border: 1px solid var(--accent-color) !default;
$muted-border-hover: 1px solid var(--darken-30-accent-color) !default;

$muted-background: transparent !default;
$muted-background-hover: transparent !default;
$muted-color: var(--darken-50-accent-color) !default;
$muted-color-hover: var(--darken-50-accent-color) !default;

$input-background: transparent !default;
$placeholder: var(--secondary-color) !default;
$form-border: 1px solid var(--border-color) !default;
$form-border-hover: 1px solid var(--darken-10-primary-color) !default;
$form-border-focus: 1px solid var(--primary-color) !default;

$code-color: var(--font-color) !default;
$code-borders: $borders !default;