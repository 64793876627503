@media (max-width: 500px) {
    .content-body {
        .-left {
            grid-area: left;
            overflow: auto;
        }
    }
}

@media (max-width: 600px) {
    table.schols-table {
        th:nth-child(3), td:nth-child(3),
        th:nth-child(4), td:nth-child(4),
        th:nth-child(5), td:nth-child(5) {
            display: none;
        }
    }

    #app {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 50px;
        grid-template-areas: 'body sidebar';

        .payment-methods-wrapper {
            min-width: 100%;

            .payment-method {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .sidebar {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            height: 50px;
            padding: 0;
            overflow: auto;
            z-index: 10;

            nav {
                ul {
                    margin-top: 0px;
                    display: flex;
                    li {
                        flex: 1;
                        min-width: 57px;

                        i { margin: 0; }
                        a {
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }

        > .body {
            margin-left: 0 !important;

            > .-padding {
                max-height: calc(100vh - 115px);

                &.-iphone {
                    max-height: calc(100vh - 210px) !important;
                }
            }
        }
    }

    .scoring { visibility: hidden; }

    .main-header {
        .scoring { display: none !important; }
        .-menu { margin-left: auto !important; }
    }

    .ask-box-wrapper {
        > .-avatar { display: none; }
    }

    .view-content {
        > .content-body {
            .title-wrapper {
                display: flex;

                .-buttons {
                    margin-left: 0px;
                    margin-right: 0px !important;
                    margin-top: .5rem;

                    button {
                        margin-left: .5rem !important;
                    }

                    .-favorite { margin-left: auto; }
                }
            }
        }
    }

    .view-degree {
        > main {
            display: block;

            .modules {
                min-width: 100%;
                width: 100%;
                padding-right: 0px;
                min-height: auto;
            }

            .side {
                position: relative;
                top: auto;
                min-width: 100%;
                max-width: 100%;
                width: 100%;
            }
        }
    }

    .box { padding: 1rem; }
    .-signin {
        .box {
            padding: .5rem;
            flex: 1;
            border-radius: 0px;
        }
    }

    .modal {
        .-content {
            width: 100% !important;
            margin: 0px !important;
            border: 0px !important;
        }
    }

    .sidebar {
        width: 60px !important;

        header, li a span, footer {
            display: none;
        }

        li a i {
            font-size: 2rem !important;
        }

        li.active-secondary { display: none; }

    }

    main {
        footer {
            visibility: visible !important;
            opacity: 1 !important;
        }
    }

    .-signin, .-profile-page {
        .box {
            box-shadow: none;
            margin: 0 !important;
        }
        footer {
            margin-top: 2rem;
            li {
                a {
                    color: var(--primary-color) !important;
                }
            }
        }
    }

    .view-admin-home {
        > header {
            padding: 1rem;
            h1 {
                display: none;
            }
        }

        .banner {
            display: none;
        }

        section.support-channels {
            grid-template-columns: 1fr;
        }

        section.overview {
            grid-template-columns: 1fr;

            > div main {
                grid-template-columns: 1fr;

                .btn.-change-plan {
                    margin-right: auto;
                    margin-left: .5rem;
                    margin-bottom: .5rem;
                }
            }

            > div.-resource-usage main > div {
                justify-content: flex-start !important;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    form .section {
        .-description {
            min-width: 100%;
            flex: 1;
            padding: 0px;
            padding-bottom: 1rem;
            text-align: left;
        }

        .-fields {
            min-width: 100%;
            flex: 1;
            padding: 0px;
            box-shadow: none;
        }
    }
}
