.view-my-account {
    padding-bottom: 3rem;

    .field-wrapper.-switch {
        margin-top: 1rem;

        padding-top: 0px;
        border-bottom: 0px;
        border-top: 0px !important;

        label {
            align-items: center;
        }
    }

    .img-uploader-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .img-uploader-wrapper .-default {
        max-width: 22rem;
        position: relative;
        margin-right: 1rem;
    }

    .img-uploader-wrapper .-logo-wrapper.-square {
        max-width: 150px;

        img {
            border-radius: .42rem;
        }
    }

    .-preview {
        flex: 1;
        text-align: left;
        min-width: 20rem;
    }

    .-remove {
        position: absolute;
        top: 10px;
        right: 10px;
    }

}
