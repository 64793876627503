.view-admin-home {
    padding-top: 0px;

    > header {
        background-color: var(--primary-color);

        width: 100%;
        height: 100%;
        background-image: url(../../assets/bg-waves.png);
        background-size: 70%;
        background-repeat: repeat-y;
        background-position: right;

        border-radius: .5rem;
        padding: 3rem;
        width: 100%;

        position: relative;

        h1 {
            font-size: 2rem;
            padding: 0px;
            margin: 0px;
            font-weight: normal;

            color: var(--white-color);
            text-shadow: 1px 1px var(--shadow-primary-color);
        }

        p {
            font-size: 1.2rem;
            margin-top: .5rem;

            color: var(--white-color);
        }

        .-buttons {
            margin-top: 1rem;

            button {
                margin-right: .5rem !important;
                font-size: 1.2rem;
                font-weight: normal;

                @include box-shadow(
                10px 10px 25px var(--shadow-inverse-color),
                -5px -5px 25px var(--shadow-inverse-color));

                &:hover {
                    @include box-shadow(
                    10px 10px 25px var(--shadow-2-highlight),
                    -5px -5px 25px var(--shadow-2-highlight));
                }
            }

            .-money {
                background-color: var(--darken-10-success-color);
                border-color: var(--darken-5-success-color);
                text-shadow: 1px 1px rgba(var(--darken-25-success-color), .4);
            }

            .-points {
                background-color: var(--darken-5-primary-color);
                border-color: var(--darken-5-primary-color);
                text-shadow: 1px 1px var(--darken-25-primary-color);
            }
        }

        .banner {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            padding-left: 1rem;
            font-size: 1rem;

            color: var(--white-color);
            text-shadow: 1px 1px var(--shadow-primary-color);
        }

        @include box-shadow(
        10px 10px 25px var(--shadow-inverse-color),
        -5px -5px 25px var(--shadow-inverse-color));
    }

    section.message {
        padding: 5rem 0px;
        text-align: center;
        font-size: 1.5rem;
    }

    section.support-channels {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 1.5rem;
        grid-column: 1;

        > div {
            border-radius: .5rem;
            padding: 2rem;

            display: flex;
            flex-direction: row;

            @include box-shadow(
            10px 10px 10px var(--shadow-inverse-color),
            -5px -5px 10px var(--shadow-inverse-color));

            &:hover {
                @include box-shadow(
                10px 10px 10px var(--shadow-2-highlight),
                -5px -5px 10px var(--shadow-2-highlight));
            }

            .-icon {
                max-width: 70px;
                img {
                    width: 100%
                }
            }

            .-description {
                margin-left: 2rem;
                h4 {
                    margin: 0px;
                    padding: 0px;
                    font-weight: normal;
                    font-size: 1.4rem;
                }

                p {
                    font-size: 1rem;
                    margin-top: .3rem;
                }
            }
        }
    }

    section.overview {
        margin-top: 2rem;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 1.5rem;
        grid-column: 1;

        header {
            border-radius: .5rem .5rem 0px 0px;

        }

        > div {
            border-radius: .7rem;
            background: var(--background-color);

            @include box-shadow(
                10px 10px 10px var(--shadow-inverse-color),
                -5px -5px 10px var(--shadow-inverse-color));

            @include box-shadow(
                10px 10px 10px var(--shadow-2-inverse-color),
                -5px -5px 10px var(--shadow-2-inverse-color));

            &:hover {
                @include box-shadow(
                10px 10px 10px var(--shadow-3-highlight),
                -5px -5px 10px var(--shadow-3-highlight));
            }

            header {
                padding: .7rem 1rem;
                text-align: center;
                background: var(--lighten-15-secondary-color);

                h3 {
                    font-weight: normal;
                    margin: 0px;
                    padding: 0px;
                    font-size: 1.2rem;
                    color: var(--primary-color);
                }

                position: relative;
            }

            &.-resource-usage {
                main {
                    justify-content: center;
                    > div { justify-content: center; }
                }
            }

            .change-plan-wrapper {
                padding: 0px;
                padding-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button {
                    padding: .5rem;
                    background: transparent;
                    height: min-content;
                    width: max-content;
                    padding: .5rem;

                    border: 1px solid var(--primary-color);
                    color: var(--primary-color);
                }
            }

            main {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
                grid-gap: .5rem;
                grid-column: 1;

                > div {
                    padding: 1rem;

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    i {
                        color: var(--primary-color);
                        width: 2rem;
                        font-size: 2rem;
                        margin-top: -.3rem;
                    }

                    span {
                        margin-left: .5rem;
                        font-size: 1.2rem;
                        color: var(--inverse-color);
                    }
                }
            }
        }
    }
}
