.checkout-wrapper {
    .on-demand-wrapper {
        flex: 1;

        p {
            color: var(--primary-red);
            font-size: 1rem;
        }

        button {
            margin-top: 1rem;
            margin-right: 1rem;
        }
    }
}

.plans-wrapper {
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
    grid-template-rows: min-content;
    grid-gap: 1.5rem;
    grid-column: 1;

    padding-top: 1rem;

    .-plan {
        height: min-content;
        border-radius: .7rem;

        @include box-shadow(
            10px 10px 10px var(--shadow-2-inverse-color),
            -5px -5px 10px var(--shadow-2-inverse-color));

        &:hover {
            @include box-shadow(
            10px 10px 10px var(--shadow-3-highlight),
            -5px -5px 10px var(--shadow-3-highlight));
        }

        &.-ondemand {
            border-color: var(--primary-color);
            header {
                background: var(--primary-color);
            }
            footer {
                button {
                    border-color: var(--primary-color);
                    background: var(--primary-color);
                }
            }
            main {
                .highlight {
                    h4 { color: var(--primary-color); }
                    span { color: var(--primary-color); }
                }
            }
            .features { ul li i { color: var(--primary-color); } }
            .additional { span { color: var(--primary-color); } }
            .description { p { color: var(--primary-color); } }
        }

        &.-middle {
            border-color: var(--alternate-color);
            header {
                background: var(--alternate-color);
            }
            footer {
                button {
                    border-color: var(--alternate-color);
                    background: var(--alternate-color);
                }
            }
            main {
                .highlight {
                    h4 { color: var(--alternate-color); }
                    span { color: var(--alternate-color); }
                }
            }
            .features { ul li i { color: var(--alternate-color); } }
            .additional { span { color: var(--alternate-color); } }
            .description { p { color: var(--alternate-color); } }
        }

        &.-super {
            border-color: var(--success-color);
            header {
                background: var(--success-color);
            }
            footer {
                button {
                    border-color: var(--success-color);
                    background: var(--success-color);
                }
            }
            main {
                .highlight {
                    h4 { color: var(--success-color); }
                    span { color: var(--success-color); }
                }
            }
            .features { ul li i { color: var(--success-color); } }
            .additional { span { color: var(--success-color); } }
            .description { p { color: var(--success-color); } }
        }

        header {
            padding: 1.2rem 1rem;
            text-align: center;
            border-radius: .5rem .5rem 0px 0px;

            h3 {
                font-weight: normal;
                margin: 0px;
                padding: 0px;
                font-size: 1.2rem;
                color: var(--white-color);
            }

            position: relative;

            .active-badge {
                font-size: .9rem;
                top: .2rem;
                right: .2rem;
                padding: .1rem .5rem;
                position: absolute;
                border-radius: .2rem;
                background-color: var(--darken-10-success-color);

                color: var(--white-color);
                @include box-shadow(
                    5px 5px 5px var(--shadow-success-color),
                    -2px -2px 5px var(--shadow-success-color));
            }
        }

        footer {
            padding: 1rem;

            button {
                -webkit-appearance: none;
                display: inline-block;
                padding: 0.75rem 1.25rem;
                margin: 0 0 0 0;
                vertical-align: middle;
                text-align: center;
                cursor: pointer;
                text-decoration: none;
                line-height: 1;
                width: 100%;
                text-align: center;
                font-weight: normal !important;
                font-size: 1.5rem !important;

                border: $button-border-width $button-border-style $button-border-color;
                border-radius: $button-border-radius;
                color: $button-color;
                font-weight: $button-font-weight;
                font-family: $button-font-family;
                font-size: $button-font-size;
                text-transform: $button-text-transform;

                @include box-shadow(
                    5px 5px 5px var(--shadow-3-highlight),
                    -2px -2px 5px var(--shadow-3-highlight));
            }
        }

        main {
            padding: 1rem;

            .highlight {
                text-align: center;

                h4 {
                    margin: 0px;
                    padding: 0px;
                    font-size: 1.5rem;
                }

                span {
                    display: block;
                    margin-top: -.2rem;
                    font-size: 1.2rem;
                }

                padding-bottom: 1rem;
            }

            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;

                li {
                    font-size: 1.1rem;
                    color: var(--inverse-color);

                    i {
                        font-size: 1.5rem;
                        margin-right: .3rem;
                    }
                }
            }


            .description {
                padding: 1.5rem .5rem;
                text-align: center;
            }

            .additional {
                span {
                    font-size: .9rem;
                }

                li {
                    font-size: .9rem;

                    i {
                        font-size: 1.2rem;
                        margin-right: .2rem;
                        color: var(--lighten-10-inverse-color);
                    }
                }
            }
        }
    }
}
