// Media query for mobile first layout
@mixin small-breakpoint {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

// Break on tablet
@mixin medium-breakpoint {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

// Break on desktop
@mixin large-breakpoint {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;       
            box-shadow: $shadow;
  }