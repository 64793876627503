@keyframes logo-hideshow {
    0% { opacity: 1; }
    10% { opacity: 1; }
    15% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes logo-pop {
    0% { transform: translateY(0px); }
    50% { transform: translateY(0px); }
    100% { transform: translateY(500px); }
}

.logo {
    img { width: 100%; }
    svg {
        transition: .5s all;
        fill: var(--primary-color);
        min-width: 100px;
    }

    svg.svg-logo-square, .logo-square_svg__svg-logo-square {
        .-bg, .logo-square_svg__-bg {
            transition: .5s all;
            fill: var(--primary-color);
            stroke: var(--primary-color);
            stroke-miterlimit: 10;
            stroke-width:10px;
        }

        .-line, .logo-square_svg__-line {
            fill: var(--white-color);
        }
    }

    svg.svg-logo, .logo_svg__svg-logo {
        &:not(:focus) {
            path {
                transform: translateY(0px);
            }
        }

        &:hover {
            path {
                animation: logo-pop 3s ease alternate infinite;
                animation-fill-mode: forwards;

                &.-i, &.logo_svg__-i {
                    animation-delay: 1.5s;
                }

                &.-e, &.logo_svg__-e {
                    animation-delay: 0.2s;
                }

                &.-s, &.logo_svg__-s {
                    animation-delay: 0.4s;
                }

                &.-ioff, &.logo_svg__-ioff {
                    animation-delay: 0.4s;
                }

                &.-frame, .logo_svg__-frame {
                    animation: none;
                }
            }
        }
    }

    &.-secondary { svg { fill: var(--secondary-color); } }
    &.-loading {
        path {
            animation: logo-hideshow 1s ease alternate infinite;

            &.-i {
                animation-delay: 0.1s;
            }

            &.-e {
                animation-delay: 0.4s;
            }

            &.-s {
                animation-delay: 0.7s;
            }

            &.-ioff {
                animation-delay: 0.8s;
            }

            &.-frame {
                animation-delay: 0.1s;
            }
        }
    }
}
