span.-editable {
    cursor: pointer;
    border-bottom: 2px dashed var(--lighten-20-primary-color);
}

.view-class {
    h2 {
        border-bottom: 2px dashed var(--lighten-20-secondary-color);
    }


    .integration-list-wrapper, .student-list-wrapper {
        margin-top: 2rem;

        header {
            margin-top: .5rem;
            margin-bottom: 1rem;

            button {
                margin-right: 1rem;
            }
        }
    }

    .link-remove {
        margin-top: 1rem;
        a {
            color: var(--inverse-color);
        }
    }
    .service-description {
        padding: .5rem;
        margin-bottom: 1.5rem;
        text-align: center;
        border-radius: .5rem;

        border: 1px solid var(--highlight);
        background: var(--shadow-3-secondary-color);
    }

    table.integrations-table {
        th, td {
            &.-name {
                width: 200px;
            }
            &.-codes {
                width: 200px;
            }
            &.-ids {
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;
            }
            &.-actions {
                width: 200px;
                text-align: right;
            }
        }
    }

    .integrations-list {
        display: flex;
        margin-bottom: 1rem;

        .-integration {
            margin-top: .5rem;
            margin-right: .5rem;
            padding: .5rem;
            border-radius: .5rem;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            cursor: pointer;
            border: 1px solid var(--lighten-20-secondary-color);
            &:hover {
                @include box-shadow(
                10px 10px 10px var(--shadow-2-highlight),
                -5px -5px 10px var(--shadow-2-highlight));
            }

            &.-active {
                border: 1px solid var(--lighten-5-highlight);
            }

            img {
                max-width: 6.5rem;
            }

            h4 {
                font-size: 1.2rem;
                font-weight: normal;
                padding: 0px;
                margin: 0px;
            }
        }
    }
}

.-select-degree {
    .all-switchers {
        margin-top: 1rem;
    }

    .degrees-wrapper {
        width: 100%;
        border-radius: .5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;

        overflow: auto;
        border: 1px solid var(--lighten-20-secondary-color);

        .-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            background: var(--lighten-20-inverse-color);

            h5 {
                padding: 0px;
                margin: 0px;
                font-size: 1.15rem;
                font-weight: normal;
            }
        }

        .-degree {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .5rem 1rem;
            p {
                font-size: 1rem;
            }

            cursor: pointer;

            &:nth-child(odd) {
                background-color: var(--lighten-26-inverse-color);
            }

            &:nth-child(even) {
                background-color: var(--lighten-25-inverse-color);
            }

            &:hover {
                background: var(--lighten-40-highlight);
            }
        }
    }
}
