html.theme, html, body, #root {
    width: 100%;
    height: 100%;
}
h3 {
    &.-normal {
        font-weight: normal;
    }
}
p {
    margin: 0px;
    padding: 0px;
}

a {
    color: var(--link-color);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: var(--link-color-hover);
        text-decoration: underline;
    }
}

mark {
    background: var(--highlight);
    padding: 0 0.2rem;
}

pre {
    border: 0;
    border-radius: $border-radius;
    background: $code-background;
    padding: 1rem;
    tab-size: 2;
    color: $code-color;
    font-family: $code-family;
    font-size: $code-size;
    margin: 0 0 $margins 0;

    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}

blockquote {
    margin: 0 0 $margins 0;
    padding: 0 1.5rem;
    font-size: 1.5rem;

    cite {
        display: block;
        margin-top: $margins;
        font-size: 1rem;
        text-align: right;
    }
}

kbd {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    color: #333;
    display: inline-block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.4;
    margin: 0 0.1em;
    padding: 0.1em 0.6em;
    text-shadow: 0 1px 0 #fff;
}

:not(pre) > code {
    color: $code-color;
    background: $code-background;
    font-family: $code-family;
    font-size: $code-size;
    padding: 0 0.2rem;
    border: $borders;
    border-radius: $border-radius;
}

hr {
    height: 0;
    border: 0;
    border-top: $borders;
}
dt {
    font-weight: 600;
}

dd {
    margin-bottom: 0.5rem;
}

span, p {
    &.-secondary {
        color: var(--inverse-color);
    }
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}