.app.-signin {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .box {
        padding: 1rem;

        .logo {
            width: 250px;
            padding: 1rem;

            svg {
                width: 150px;
            }
        }

        main {
            padding: 1rem;
            padding-top: 0px;
            form {

                margin-top: 1rem;
            }
        }
    }

    footer {
        position: absolute;
        right: 1rem;
        bottom: 1rem;

        text-align: right;

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
        }

        li a {
            padding: .2rem 0px;
            font-size: 1rem;
        }

        li.copyright {
            padding: .2rem 0px;
            font-size: 1rem;
        }
    }

    .field-wrapper.-switch {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}