.app.-login {
    font-size: 1rem;
    display: flex;

    .box {
        border-radius: 0px;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include box-shadow(
        10px 10px 25px var(--shadow-3-secondary-color),
        -5px -5px 25px var(--shadow-3-secondary-color));

        .logo {
            width: 250px;
            padding: 1rem;

            svg {
                width: 150px;
            }
        }

        main {
            padding: 1rem;
            flex: 1;
        }

        footer {
            padding-top: 3rem;
            text-align: left;

            ul {
                margin: 0px;
                padding: 0px;
                list-style: none;
            }

            li a {
                padding: .2rem 0px;
                font-size: 1rem;
                color: var(--black-color);
            }

            li.copyright {
                padding: .2rem 0px;
                font-size: 1rem;
                color: var(--inverse-color);
            }
        }
    }
}