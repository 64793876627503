.view-degrees {
    padding-bottom: 3rem;

    h2 {
        font-size: 1.5rem;
        font-weight: normal;
        padding: 0px;
        margin-top: .5rem;
    }

    &.-student {
        h2 {
            border-bottom: 2px solid var(--lighten-20-secondary-color) !important;
        }
    }

    .add-category {
        color: var(--inverse-color);
        border: 2px dashed var(--secondary-color);

        cursor: pointer;
        transition: all .5s ease;

        padding: 1rem;

        margin-bottom: 1rem;

        background: none;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        border-radius: .5rem;

        i {
            margin-top: -.2rem;
            margin-right: .5rem;
        }

        &:hover, &:active {
            border: 2px dashed var(--highlight);
            background: var(--lighten-40-highlight) !important;
            color: var(--font-color) !important;
        }
    }

    .category-degrees {
        padding-top: .5rem;
        margin-bottom: 1rem;

        header {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            padding: 0px;
            margin: 0px;

            i {
                font-size: 1.5rem;
                color: var(--inverse-color);
            }

            h2 {
                font-size: 1.5rem;
                margin: 0px;
                padding: 0px;
                flex: 1;
                font-weight: normal;
                border-bottom: 2px dashed var(--lighten-20-secondary-color);
            }

            margin-bottom: 1rem;

        }

        &.-readonly {
            .-dragger { display: none; }

            h4 {
                border-bottom: 2px solid var(--lighten-20-inverse-color);
            }
        }

        .add-degree {
            border-radius: 1rem;
            text-align: center;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all .5s ease;

            height: 20rem;

            cursor: pointer;

            color: var(--inverse-color);
            border: 2px dashed var(--secondary-color);

            @include box-shadow(
            10px 10px 10px var(--shadow-2-inverse-color),
            -5px -5px 10px var(--shadow-2-inverse-color));

            &:hover, &:active {
                border: 2px dashed var(--highlight);
                background: var(--lighten-40-highlight) !important;
                color: var(--font-color) !important;
            }
            i {
                font-size: 2rem;
            }
            p {
                font-size: 1.3rem;
            }
        }

        .degrees-list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
            grid-auto-rows: auto;
            grid-gap: 1.2rem;
            grid-column: 1;

            .degree {
                cursor: pointer;

                background: var(--primary-color);
                background: linear-gradient(180deg,
                var(--primary-color) 0%,
                var(--primary-color) 35%,
                var(--primary-color) 100%);


                @include box-shadow(
                10px 10px 10px var(--shadow-2-inverse-color),
                -5px -5px 10px var(--shadow-2-inverse-color));

                &.-drag {
                    cursor: grab;
                }

                height: 20rem;
                border-radius: 1rem;
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                position: relative;
                padding: 0px 2rem;

                .-badge {
                    cursor: pointer;
                    z-index: 5;
                    border-radius: .3rem;
                    padding: .5rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    background: var(--shadow-2-inverse-color);
                    background: linear-gradient(180deg,
                        var(--shadow-2-inverse-color) 0%,
                        var(--shadow-2-inverse-color) 35%,
                        var(--shadow-3-inverse-color) 100%);

                    color: var(--lighten-30-inverse-color);

                    @include box-shadow(
                    5px 5px 5px var(--shadow-2-inverse-color),
                    -2px -2px 5px var(--shadow-2-inverse-color));

                    &.-public, &.-invisible, &.-published, &.-draft, &.-favorite, &.-lock {
                        background: transparent;
                        position: absolute;
                        width: auto;
                    }
                    &.-public, &.-invisible {
                        bottom: .5rem;
                    }
                    &.-published, &.-draft, &.-favorite, &.-lock {
                        top: .5rem;
                    }

                    &.-published, &.-lock { left: .5rem; }
                    &.-draft, &.-favorite { right: .5rem; }
                    &.-public { left: .5rem; }
                    &.-invisible { right: .5rem; }

                    &.-continue, &.-start {
                        background: var(--primary-color);
                        background: linear-gradient(180deg,
                        var(--darken-2-primary-color) 0%,
                        var(--darken-5-primary-color) 35%,
                        var(--darken-8-primary-color) 100%);

                        @include box-shadow(
                        10px 10px 10px var(--box-shadow-primary-color),
                        -5px -5px 10px var(--box-shadow-primary-color));
                    }
                    &.-published { color: var(--lighten-15-success-color); }


                    i, span {
                        margin: .2rem;
                        text-shadow: .5px 1px var(--shadow-2-secondary-color);
                    }

                    i {
                        margin-top: -.1rem;
                    }

                    span {
                        font-size: 1.2rem;
                    }
                }

                p {
                    z-index: 5;
                    width: 100%;
                    text-align: left;
                    margin-bottom: .5rem;
                    font-size: 1rem;
                    cursor: pointer;
                    color: var(--white-color);

                    i {
                        font-size: 1.3rem;
                        margin-right: .2rem;
                        color: var(--white-color);
                    }
                }

                h4 {
                    font-weight: normal;
                    font-size: 1.5rem;
                    z-index: 5;
                    border-bottom: 0px;
                    text-align: center;
                    cursor: pointer;

                    color: var(--white-color);
                    text-shadow: 1px 1px var(--shadow-3-secondary-color);
                }

                &:hover {
                    background: var(--primary-color);
                    background: linear-gradient(180deg,
                    var(--primary-color) 0%,
                    var(--primary-color) 35%,
                    var(--darken-8-primary-color) 100%);

                    @include box-shadow(
                    10px 10px 10px var(--shadow-2-highlight),
                    -5px -5px 10px var(--shadow-2-highlight));
                }

                .-background {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    z-index: 3;

                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;

                    .overlay {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        opacity: .6;

                        background: var(--black-color);
                        background: linear-gradient(180deg,
                        var(--black-color) 0%,
                        var(--black-color) 35%,
                        var(--black-color) 100%);
                    }

                    border-radius: .5rem;

                    img {
                        object-fit: cover;
                        width: 100%;
                        object-position: center;
                        height: 20rem;
                    }
                }
            }
        }
    }

    .add-category.-remove {
        transition: all 0s ease !important;
        border-color: var(--error-color) !important;
    }
}
