@keyframes glow {
    from {
        text-shadow:
        0 0 10px #fff,
        0 0 20px var(--highlight),
        0 0 30px var(--highlight);
    }

    to {
        text-shadow:
        0 0 10px #fff,
        0 0 20px var(--lighten-10-highlight),
        0 0 30px var(--lighten-10-highlight);
    }
}

h2, h4, h3, h5 { color: var(--darken-20-inverse-color); }

.material-icons notranslate {
    vertical-align: middle;
}

body {
    .-dragger { cursor: grab !important; }
    .sortable-chosen,
    .sortable-chosen .-dragger {
        cursor: grabbing !important;
    }

    color: var(--font-color);
    background: var(--background-color);
    font-size: $body-font-size;
}

.theme, html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: $font-style $font-variant $font-weight #{$font-size}/#{$line-height} $font-family;
    font-size: $font-size;
}

.context-body {
    display: flex;
    width: 100%;
    height: 100%;

    opacity: 1;

    &.-hide {
        opacity: 0;
    }
}
.context-loading {
    background-color: var(--shadow-inverse-color);
    display: flex;
    flex: 1;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    transition: opacity .4s, visibility .4s;

    position: absolute;

    z-index: 10;

    &.-hide {
        opacity: 0;
        visibility: hidden;
    }

    .logo {
        width: 40%;
        margin: auto;
    }
}

.loading-mask {
    background-color: var(--shadow-inverse-color);

    display: flex;
    flex: 1;
    padding: 1rem;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    transition: opacity .3s, visibility .3s;

    position: absolute;
    width: 100%;
    height: 100%;

    &.-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
    }

    z-index: 10;

    &.-hide {
        opacity: 0;
        visibility: hidden;
    }

    .logo {
        width: 40%;
        margin: auto;
    }
}

#app {
    display: grid;
    grid-template-columns: auto 9fr;
    grid-template-areas: 'sidebar body';

    overflow-x: hidden;

    width: 100%;
    height: 100%;

    .sidebar {
        background: var(--background-color);

        @include box-shadow(
            10px 10px 25px var(--shadow-inverse-color),
            -5px -5px 25px var(--shadow-inverse-color));

        position: fixed;
        height: 100vh;
        grid-area: sidebar;

        transition: width .5s;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 17rem;

        h3 {
            font-size: 1.75rem !important;
        }

        &.-hide, &.-closed, &.-closed-right {
            width: 0px;
        }

        &.-small {
            width: 60px;

            header, li a span, footer {
                visibility: hidden;
                opacity: 0;
                width: 0px;
                height: 0px;
            }

            li a i {
                font-size: 2rem;
            }

            li a svg {
                width: 1.8rem !important;
            }
        }

        li a .logo {
            margin-right: 1rem;
            margin-left: .2rem;
            padding-top: .3rem;

            svg {
                width: 1.3rem;
                transition: color 0s, width .5s ease, height .5s ease;
            }
        }

        li a i, li a, li, header, footer {
            transition:
                opacity .2s ease,
                visibility .2s ease,
                width .3s ease,
                height .3s ease,
                font .3s ease;
        }

        header, footer {
            overflow: hidden;
            white-space: nowrap;
        }

        header {
            width: 100%;
            height: 100px;
            h3 {
                color: var(--darken-25-inverse-color);
                padding-top: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-bottom: 0px;
            }

            p {
                color: var(--inverse-color);

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: .8rem;

                .-role {
                    cursor: pointer;
                    border-bottom: 1px dashed var(--primary-color);

                    &.-student {
                        border-bottom: 0px !important;
                        cursor: initial !important;
                    }
                }
            }
        }

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            margin-top: .5rem;

            li, a, i {
                opacity: 1;
            }

            .logo {
                svg {
                    .-bg {
                        fill: var(--secondary-color);
                        stroke: var(--secondary-color);
                    }
                    .-line { fill: var(--white-color) !important; }
                }
            }

            li {
                &.active {
                    a { color: var(--primary-color); }
                    i { color: var(--primary-color); }
                    svg .-bg {
                        fill: var(--primary-color) !important;
                        stroke: var(--primary-color) !important;
                    }
                }

                &:hover {
                    a {
                        color: var(--darken-20-primary-color);
                        i { color: var(--darken-10-primary-color); }
                        svg .-bg {
                            fill: var(--darken-10-primary-color) !important;
                            stroke: var(--darken-10-primary-color) !important;
                        }
                    }
                }
                a {
                    display: block;
                    padding: .7rem 1rem;
                    line-height: 1.5rem;
                    display: flex;
                    margin: 0px;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 1.2rem;
                    color: var(--inverse-color);

                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                    }

                    i {
                        font-size: 1.7rem;
                        margin-right: .75rem;
                        color: var(--inverse-color);
                    }

                    span {
                        overflow: hidden;

                        width: 100%;
                        white-space: nowrap;

                        padding-top: .2rem;
                        opacity: 1;
                    }
                }
            }
        }

        footer {
            padding-bottom: 5rem;
            width: 100%;
            height: 80px;

            li a {
                padding: .2rem 1rem;
                font-size: 1rem;
                color: var(--black-color);
            }

            li.copyright {
                padding: .2rem 1rem;
                font-size: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--inverse-color);
            }
        }
    }

    .body {
        grid-area: body;
        display: flex;
        flex-direction: column;
        margin-left: 17rem;
        transition: margin .5s ease;

        &.sidebar-small {
            margin-left: 60px;
        }

        > .-padding {
            overflow: auto;
            max-height: calc(100vh - 63px);
        }

        header.main-header {
            padding: 1rem 2rem 1rem 2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 60px;

            .sensei {
                height: 2.5rem;
                width: 2.5rem;
                margin-top: .6rem;
            }

            .-title {
                h2 {
                    color: var(--white-color);
                    font-size: 1.5rem;
                    padding: 0px;
                    margin: 0px;
                    display: inline-flex;
                    font-weight: normal;
                }
                i {
                    margin-right: 1rem;
                    font-size: 2rem;
                    cursor: pointer;
                    margin-top: -.25rem;
                    color: var(--white-color);
                }
            }

            .scoring {
                margin-left: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: .4rem;

                .-money, .-points {
                    &:hover {
                        text-shadow:
                        0 0 20px var(--lighten-30-highlight),
                        0 0 30px var(--lighten-30-highlight);
                    }

                    margin-right: .5rem;
                    padding-right: 1rem;

                    cursor: pointer;

                    font-size: 1.7rem;
                    font-weight: bold;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    &.-points {
                        .-icon {
                            background-color: var(--primary-color);
                        }
                    }

                    .-icon {
                        background-color: var(--success-color);
                        margin-top: -.4rem;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 1rem;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: .7rem;

                        i {
                            font-size: 1.6rem;
                            color: var(--white-color);
                        }
                    }

                    &:last-child {
                        border-right: 0px;
                    }

                    .-currency {
                        display: block;
                    }

                    .-currency, .-cents {
                        font-size: .8rem;
                        margin-right: .5rem;
                    }

                    .-money-value {
                        margin-top: -.4rem;
                        padding: 0px;
                        height: 1.8rem;
                    }

                    .-currency {
                        margin-top: -.3rem;
                    }
                }
            }

            ul.-menu {
                list-style: none;
                margin: 0;
                padding: 0;

                &.-content {
                    margin-left: auto;
                }

                display: flex;

                li {
                    padding: 0px .4rem;
                    position: relative;

                    &:hover {
                        ul.-dropdown {
                            display: block;
                        }
                    }

                    a {
                        transition: color .7s ease;
                        color: var(--lighten-10-inverse-color);
                        &:hover {
                            color: var(--darken-10-inverse-color);

                            i.-badge:after {
                                background: var(--error-color);
                            }
                        }

                        i {
                            font-size: 2rem;
                            padding: 3px;
                        }

                        img.-avatar {
                            object-fit: cover;
                            width: 100%;
                            object-position: center;
                            height: 2.5rem;
                            width: 2.5rem;
                            border-radius: .2rem;
                            margin-top: .6rem;
                        }

                        i.-avatar {
                            transition: color .5s ease;
                            border-radius: .2rem;

                            background: var(--lighten-22-inverse-color);
                            color: var(--primary-color);
                        }

                        i.-badge {
                            position: relative;

                            &:after{
                                background: var(--lighten-10-error);

                                content: "1";
                                position: absolute;
                                left: .4rem;
                                top: .4rem;
                                height: .5rem;
                                width: .5rem;
                                text-align: center;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            ul.-dropdown {
                background-color: var(--lighten-background-color);

                @include box-shadow(
                    10px 10px 10px var(--shadow-inverse-color),
                    -5px -5px 10px var(--shadow-inverse-color));

                z-index: 15;
                display: none;
                list-style: none;
                margin: 0px;
                padding: 0px;

                position: absolute;

                right: 5px;
                top: 90%;

                width: 100%;
                min-width: 200px;;
                max-width: 120px;
                border-radius: .5rem;

                li {
                    padding-left: .6rem;
                    padding-right: .6rem;
                    padding-top: .6rem;
                    padding-bottom: .4rem;
                    border-radius: .5rem;

                    &:hover {
                        color: var(--primary-color);
                        background-color: var(--lighten-15-inverse-color);
                    }

                    a {
                        transition: color .7s ease;
                        color: var(--darken-33-primary-color);

                        align-items: center;
                        position: relative;
                        display: flex;
                        text-decoration: none;

                        &:hover {
                            color: var(--darken-33-primary-color);
                        }

                        i {
                            font-size: 1.3rem;
                            margin-right: .5rem;
                            margin-left: .2rem;
                        }
                    }
                }
            }

            .burger-menu {
                cursor: pointer;
                text-align: right;
                color: var(--inverse-color);
            }

            .logo {
                cursor: pointer;
                height: 60px;
                padding-top: .4rem;

                svg {
                    max-width: 100px;
                }

                img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        > main {
            &.-padding {
                padding: 0px 2rem;
            }

            width: 100%;
            display: flex;
            flex: 1;
            flex-direction: column;
            .-body {
                flex: 1;
            }

            > footer {
                display: none;
                margin-left: auto;
                transition: visibility .5s, opacity .5s linear;
                overflow: hidden;
                white-space: nowrap;

                visibility: hidden;
                opacity: 0;

                &.-visible {
                    visibility: visible;
                    opacity: 1;
                }

                ul {
                    list-style: none;
                    margin: 0px;
                    padding: 0px;

                    display: flex;
                    flex-direction: row;

                    li {
                        margin-left: 2rem;
                        margin-top: 1rem;

                        a {
                            color: var(--black-color);
                        }

                        &.copyright {
                            color: var(--inverse-color);
                        }
                    }
                }
            }
        }
    }

    > main {
        nav.tabs {
            button.btn {
                margin-right: 1rem;
            }
        }
    }
}

.box {
    padding: 2rem;
    background-color: var(--background-color);
    border-radius: 1rem;

    &.-size-1 {
        width: 100%;
        max-width: 720px;
    }

    &.-size-2 {
        width: 100%;
        max-width: 550px;
    }
}

.custom-page.-profile-page {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    overflow: auto;

    .box {
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 900px;
        border-radius: .4rem;
    }

    footer {
        text-align: center;

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
        }

        li a {
            padding: .2rem 0px;
            font-size: 1rem;
        }

        li.copyright {
            padding: .2rem 0px;
            font-size: 1rem;
        }
    }

}



.box.-profile {
    .awards {
        border-top: 1px solid var(--lighten-15-secondary-color);
        header {
            i { color: var(--highlight); }
        }
    }
    .progress {
        margin-top: 1rem;
        border-top: 1px solid var(--lighten-23-secondary-color);
        header {
            i { color: var(--secondary-color); }
        }
    }

    .user-profile {
        display: flex;
        flex-direction: row;

        .profile-img {
            max-width: 10rem;
            img { width: 90%; }
            text-align: center;

            svg {
                width: 100%;
                height: 10em;
            }
        }

        .profile-content {
            padding: .5rem;
            flex: 1;
        }
    }

    main { padding: 0px !important; }

    h2 {
        margin: 0px;
        padding: 0px;
        font-weight: normal;
    }
    p {
        margin: 0px;
    }

    .awards, .progress {
        padding-top: 1rem;
        header {
            display: flex;
            flex-direction: row;
            align-items: center;

            i {
                margin-top: -.3rem;
                font-size: 3rem;
                margin-right: 1rem;
            }
        }
    }
}


.alert {
    width: 100%;
    padding: .5rem .8rem;
    border-radius: .4rem;

    margin-top: 1rem;
    margin-bottom: 1rem;

    &.-error {
        background-color: var(--shadow-error-color);
        border: 2px solid var(--lighten-30-error);
    }

    &.-success {
        background-color: var(--shadow-success-color);
        border: 2px solid var(--lighten-30-success-color);
    }
}

.custom-page {
    width: 100%;
    height: 100%;

    background-color: var(--primary-color);
    background-image: url(../assets/bg-waves.png);
    background-size: cover;
    background-repeat: repeat-y;

    .heading-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 2.2rem;
            color: var(--primary-color);
        }
    }
}

.context-switch {
    margin-left: auto;
    margin-bottom: .5rem;
    margin-right: 1rem;
    cursor: pointer;

    select {
        padding: .50rem;
        text-align: center;

        border: 1px solid var(--lighten-10-secondary-color);
        color: var(--secondary-color);
    }
}

.ql-editor {
    padding: 0px !important;
    padding-top: .2rem !important;
    padding-bottom: .5rem !important;
}

.html-page {
    margin-top: 1rem;
    padding: 1.5rem;
    border-radius: .5rem;

    @include box-shadow(
    10px 10px 25px var(--shadow-secondary-color),
    -5px -5px 25px var(--shadow-secondary-color));

    a {
        color: var(--primary-color) !important;
        text-decoration: underline;
        cursor: pointer;
    }

    h2 {
        padding: 0px;
        margin: 0px;
        font-weight: normal;
    }
}

.theme-selector-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));;
    grid-gap: .7rem;
    grid-column: 1;

    .theme {
        flex: 1;
    }
}

.theme-selection {
    border: 1px solid var(--secondary-color);

    border-radius: .5rem;
    text-align: center;
    cursor: pointer;

    label {
        cursor: pointer;
        font-size: 1rem;
        font-weight: normal !important;
    }

    transition: color .5s ease;

    &:hover {
        border: 1px solid var(--primary-color);

        .inactive-text {
            background-color: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
            color: var(--white-color) !important;
        }
    }

    .active-text, .inactive-text {
        background-color: var(--primary-color);
        color: var(--white-color);
        border: 1px solid var(--primary-color);
        transition: all .5s ease;
        display: flex;
        padding-top: .5rem;
        padding-bottom: .3rem;
        font-size: 1rem;
        i {
            font-size: 1.3em;
            margin-right: .5rem;
        }
        align-items: center;
        justify-content: center;
        border-radius: .3rem;
    }

    .inactive-text {
        opacity: .3;
        background-color: var(--lighten-15-secondary-color);
        border-color: var(--lighten-15-secondary-color);
        color: var(--darken-35-secondary-color);
    }

    &.-active {
        border: 1px solid var(--primary-color);
    }

    .colors {
        display: flex;
        flex-direction: row;
        width: 150px;
        margin: .5rem auto;

        > div {
            margin: 0px 5px;
            border-radius: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;

            &.-success {
                background-color: var(--success-color);
            }

            &.-primary {
                background-color: var(--primary-color);
            }

            &.-secondary {
                background-color: var(--secondary-color);
            }

            &.-highlight {
                background-color: var(--highlight);
            }

            &.-error {
                background-color: var(--error-color);
            }
        }
    }
}

.modal {
    background-color: var(--background-color);
    background-color: var(--shadow-background-color);

    display: none; 
    position: fixed; 
    z-index: 10; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 

    .-content {
        border-radius: 1rem;
        margin: 15% auto; 
        padding: 20px;
        width: 80%; 

        background-color: var(--background-color);
        border: 1px solid var(--darken-background-color);

        @include box-shadow(
        10px 10px 25px var(--shadow-secondary-color),
        -5px -5px 25px var(--shadow-secondary-color));

        .-footer {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            margin-top: 1rem;

            button {
                margin-right: 1rem;
            }
        }
    }

    &.-open {
        display: block;
    }

    &.-cropper {
        .-content {
            margin: 3% auto; /* 15% from the top and centered */
            width: 50%;

            .-body {
                align-items: center;

                .react-crop-wrapper {
                    text-align: center;
                }
            }
        }
    }
}

.img-uploader-wrapper {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: .7rem;
    grid-column: 1;

    .-default, .-dark {
        padding: 2rem;
        border-radius: .6rem;
        margin-bottom: 1rem;
        border: 1px solid var(--lighten-15-secondary-color);
    }

    .-col > div {
        position: relative;

        .-remove {
            position: absolute;
            top: 1rem;
            right: 1rem;
            color: var(--secondary-color);
        }
    }

    .-default {
        border: 1px solid var(--lighten-15-secondary-color);
        background-color: var(--white-color);
        color: #222;
        height: min-content;

        h5 {
            color: #222;
        }
    }

    .-dark {
        background-color: #222;
        color: #FFF;

        border: 1px solid lighten(#222, 15);

        h5 {
            color: #FFF;
        }
    }


    button {
        margin-top: 1rem;
        width: 100%;
        margin-right: 1rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;

        transition: all .5s ease;
    }

    .-logo-wrapper {
        max-width: 400px;
        margin: 1rem auto;

        &.-wide {
        }

        &.-square {
            max-width: 120px;
        }
    }

    img {
        max-width: 100%;
    }

    h5 {
        margin: 0px;
        padding: 0px;
        font-weight: normal;
        font-size: 1.5rem;
    }

    h5, p {
        margin-left: 1rem;
    }
}

.ql-container {
    font-family: Josefin Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
    border-bottom: 1px dashed var(--secondary-color);

    h1 {
        font-size: 1.5rem;
        padding: 0px;
        margin: 0px;
        font-weight: normal;
    }

    h2 {
        font-size: 1.3rem;
        padding: 0px;
        margin: 0px;
        font-weight: normal;
    }
}

.material-icons notranslate{
    display: inline-flex;
    vertical-align: top;
}

.ql-bubble .ql-out-bottom,
.ql-bubble .ql-out-top {
    visibility: hidden;
}


p.-highlight {
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}


.grid-wrapper {
    font-size: .8rem;
    padding-top: .5rem;

    .grid-filter {
        form {
            display: flex;
            flex-direction: row;

            .field-wrapper:first-child {
                min-width: 400px;
                margin-right: .5rem;
            }
        }
    }

    table {
        thead {
            th {
                font-weight: normal;
                padding: .5rem;

                height: 3rem;

                color: var(--white-color);
                background-color: var(--primary-color);
                text-shadow: 1px 1px --shadow-primary-color;

                &:first-child {
                    border-radius: .5rem 0px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px .5rem 0px 0px;
                }
            }
        }

        @include box-shadow(
            10px 10px 25px var(--shadow-secondary-color),
            -5px -5px 25px var(--shadow-secondary-color));

        tbody {
            tr {
                td {
                    padding: .5rem;
                    border-bottom: 1px solid var(--lighten-20-secondary-color);
                    padding: .5rem;
                    vertical-align: top;
                    height: 4.2rem;
                    background: var(--lighten-50-secondary-color);
                }

                &:hover {
                    td {
                        background-color: var(--lighten-35-highlight);
                    }
                }

                &:last-child {
                    border-bottom: 0px;
                }
            }
        }

        th, td {
            border-bottom: 0px;

            &.-id {
                width: 40px;
                text-align: center;
            }
            &.-name {
                width: 100px;
            }
            &.-cellphone {
                width: 100px;
            }
            &.-email {
                width: 200px;
            }
            &.-date {
                width: 100px;
            }
            &.-students {
                width: 100px;
            }
            &.-balance {
                width: 100px;
            }
            &.-block {
                width: 100px;
            }
            &.-actions {
                width: 100px;
                text-align: center;
            }
        }
    }
}

.pagination {
    padding: 0px .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .pages {
        display: flex;
        flex-direction: row;
        align-items: center;

        a, span {
            margin-right: .5rem;
        }
    }
}

.card-form-wrapper {
    @keyframes fade {
        from {
            opacity: 0;
            transform: scale3D(0.95, 0.95, 0.95);
        }
        to {
            opacity: 1;
            transform: scale3D(1, 1, 1);
        }
    }

    .Form {
        animation: fade 200ms ease-out;
    }

    .FormGroup {
        margin: 0 15px 20px;
        padding: 0;
        border-style: none;
        background-color: #7795f8;
        will-change: opacity, transform;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #829fff;
        border-radius: 4px;
    }

    .FormRow {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 15px;
        border-top: 1px solid #819efc;
    }

    .FormRow:first-child {
        border-top: none;
    }

    .FormRowLabel {
        width: 15%;
        min-width: 70px;
        padding: 11px 0;
        color: #c4f0ff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @keyframes void-animation-out {
        0%, to {
            opacity: 1;
        }
    }

    .FormRowInput:-webkit-autofill {
        -webkit-text-fill-color: #fce883;
        /* Hack to hide the default webkit autofill */
        transition: background-color 100000000s;
        animation: 1ms void-animation-out;
    }

    .FormRowInput {
        font-size: 16px;
        width: 100%;
        padding: 11px 15px 11px 0;
        color: #fff;
        background-color: transparent;
        animation: 1ms void-animation-out;
    }

    .FormRowInput::placeholder {
        color: #87bbfd;
    }

    .StripeElement--webkit-autofill {
        background: transparent !important;
    }

    .StripeElement {
        width: 100%;
        padding: 11px 15px 11px 0;
    }

    .SubmitButton {
        display: block;
        font-size: 16px;
        width: calc(100% - 30px);
        height: 40px;
        margin: 40px 15px 0;
        background-color: #f6a4eb;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #ffb9f6;
        border-radius: 4px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: all 100ms ease-in-out;
        will-change: transform, background-color, box-shadow;
    }

    .SubmitButton:active {
        background-color: #d782d9;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #e298d8;
        transform: scale(0.99);
    }

    .SubmitButton.SubmitButton--error {
        transform: translateY(15px);
    }

    .SubmitButton.SubmitButton--error:active {
        transform: scale(0.99) translateY(15px);
    }

    .ResultTitle {
        color: #fff;
        font-weight: 500;
        margin-bottom: 8px;
        font-size: 17px;
        text-align: center;
    }

    .ResultMessage {
        color: #9cdbff;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 1.6em;
        text-align: center;
    }

    .ResetButton {
        border: 0;
        cursor: pointer;
        background: transparent;
    }
}

.base-view {
    .box {
        max-width: 30rem;
        padding: 1rem;
        border-radius: .5rem;

        margin-top: 1rem;

        @include box-shadow(
        10px 10px 25px var(--shadow-secondary-color),
        -5px -5px 25px var(--shadow-secondary-color));
    }

    .bordered-box {
        border-radius: .7rem;
        max-width: 700px;

        background: var(--background-color);

        @include box-shadow(
            10px 10px 10px var(--shadow-inverse-color),
            -5px -5px 10px var(--shadow-inverse-color));

        border: .2rem solid var(--lighten-15-secondary-color);

        @include box-shadow(
            10px 10px 10px var(--shadow-2-inverse-color),
            -5px -5px 10px var(--shadow-2-inverse-color));

        &:hover {
            @include box-shadow(
            10px 10px 10px var(--shadow-3-highlight),
            -5px -5px 10px var(--shadow-3-highlight));
        }

        header {
            background: var(--lighten-15-secondary-color);
            padding: .7rem 1rem;
            text-align: center;
            border-radius: .5rem;
            margin-top: .1rem;
            margin-left: .1rem;
            margin-right: .1rem;

            h2 { border-bottom: 0px !important; }
            h3 {
                font-weight: normal;
                margin: 0px;
                padding: 0px;
                font-size: 1.2rem;
                color: var(--primary-color);
            }

            position: relative;
        }

        main {
            .-fields {
                padding: 1rem;

                .-field {
                    .-label {
                        margin-right: .5rem;
                        color: var(--darken-20-inverse-color);
                    }
                    .-value {
                        font-size: 1.1rem;

                    }

                }
            }

            h5 {
                margin: 0px;
                font-weight: normal;
                padding-left: 1rem;
                padding-top: 1rem;
                color: var(--darken-20-inverse-color); 
            }

            .access-data {
                padding: 1rem;
                border-top: 1px solid #F4F2F0;
            }

            .gateway-list {
                padding: 1rem;
                border-top: 1px solid var(--lighten-15-secondary-color);

                .-class {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-bottom: 1rem;

                    &:last-child {
                        padding-bottom: 0px;
                    }

                    .-name {
                        flex: 1;
                    }

                    .-remove {
                        font-size: .9rem;
                        padding: .3rem;
                    }
                }
            }
        }
    }
}

.view-class {
    .student-list-wrapper header {
        button { margin-right: 1rem !important; }
    }
}

.view-certificate {
    h2 {
        font-size: 1.5rem;
        font-weight: normal;
        border-bottom-style: dashed !important;
    }

    form {
        margin-top: 1.5rem;

        .section {
            h2 { border-bottom: 0px !important; }
        }
    }
}

.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .countdown-item {
        font-size: 40px;
        border-radius: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 30px;
        margin: 10px;
        padding-top: 10px;
        position: relative;
        width: 100px;
        height: 100px;

        background: var(--primary-color);
        color: white;

        @include box-shadow(
            10px 10px 10px var(--shadow-inverse-color),
            -5px -5px 10px var(--shadow-inverse-color));

        span {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }

        &:first-child {
            margin-left: 0px;
        }
    }

    .countdown-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
    }
}


.school-template {
    position: relative;

    .remove-school-template {
        position: absolute;
        right: 2rem;
        bottom: 1rem;
        font-size: .8rem;
        color: var(--error-color);
    }
}

.my-ask-box {
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    svg { width: 100px; }

    padding: 1rem;

    @include box-shadow(
        5px 5px 5px var(--shadow-2-inverse-color),
        -2px -2px 5px var(--shadow-2-inverse-color));

    h5 { color: var(--black-color); }
    span { color: var(--secondary-color); }
}

.ask-box-wrapper {
    display: flex;
    flex-direction: row;

    h5 {
        font-weight: normal;
        padding: 0px;
        margin: 0px;
        display: inline;
    }

    .-avatar {
        max-width: 100px;
        img { border-radius: .3rem; }
    }
    .ask-content {
        margin-left: 1rem;
        flex: 1;

        button.btn {
            margin-top: 1rem;
            padding: .5rem;
            font-size: 1rem !important;
        }
    }
}

.thread {
    margin-top: 1rem;
    padding: .2rem;

    @include box-shadow(
        0px 2px 0px var(--shadow-2-inverse-color),
        0px 0px 1px var(--shadow-2-inverse-color));


    button.btn {
        margin-top: 1rem !important;
        padding: .5rem !important;
        font-size: 1rem !important;
    }

    .ask-box-wrapper {
        .-avatar {
            img { border-radius: .3rem; }
            max-width: 35px;
            svg { width: 35px; }
        }
    }

    h5 {
        font-weight: normal;
        padding: 0px;
        margin: 0px;
        display: inline;
    }

    .-actions {
        a { font-size: .9rem; }
    }

    padding: .5rem;
    border-radius: .5rem;

    .ask-content {
        margin-left: .3rem;
        padding: .1rem;

        h5 {
            font-size: 1.1rem !important;
        }

        p {
            padding: 0px;
            margin: 0px;
            line-height: .9rem;
            font-size: .9rem !important;
        }

        h5, p {
            color: var(--darken-25-inverse-color);
        }
    }

    hr {
        margin-top: .1rem;
        border-color: var(--lighten-21-secondary-color) !important;
    }
}

.view-degrees.-awards {
    padding-bottom: 1rem;
    .degrees-list {
        grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

    }
    .degree {
        margin: 0px;
        max-height: 15rem;
    }
}

.ql-clipboard {
    position: fixed;
    display: none;

    left: 50%;
    top: 50%;
}

.-remove i {
    cursor: pointer;
    color: var(--lighten-30-error) !important;
}

.-label {
    padding: .3rem;
    padding-bottom: 0px;
    padding-top: 0px;
    border-radius: .3rem;
    font-size: .8rem;
    text-align: center;

    background-color: var(--lighten-20-secondary-color);
    color: var(--primary-color);

    &.role-admin {
        background-color: var(--primary-color);
        color: white;
    }

    &.label-middle {
        color: white;
        border-color: var(--alternate-color);
        background: var(--alternate-color);
    }

    &.label-super {
        color: white;
        border-color: var(--success-color);
        background: var(--success-color);
    }

    &.-success { color: var(--success-color) !important; }
    &.-error { color: var(--error-color) !important; }
}

.gateway-list-wrapper .-gateway p {
    display: flex;
    align-items: center;

    i {
        margin-right: 1rem !important;
    }
}

.pending-imports {
    padding: 1rem;
    border-radius: .5rem;
    margin-bottom: 1rem;
    width: max-content;

    border: 2px solid var(--darken-10-secondary-color);
    background: var(--lighten-25-secondary-color);
}

.additional, .features {
    ul {
        li {
            display: flex;
            align-items: center;
            flex-direction: row;

            i {
                padding-right: .5rem;
            }
        }
    }
}

.additional {
    ul {
        li {
            i {
                margin-right: 0px;
                margin-top: -.2rem;
            }
        }
    }
}

.app.-signin, .-profile-page {
    .box {
        @include box-shadow(
        10px 10px 25px var(--shadow-3-secondary-color),
        -5px -5px 25px var(--shadow-3-secondary-color));
    }

    footer {

        li a {
            color: var(--white-color);
        }

        li.copyright {
            color: var(--secondary-color);
        }
    }
}

.-dragger {
    cursor: pointer;
    cursor: pointer;
    color: var(--primary-color);
}

.-ghost {
    border: 2px dashed var(--highlight) !important;
    background: var(--lighten-40-highlight) !important;
    color: var(--font-color) !important;
}

span.dashed {
    cursor: pointer;
    border-bottom: 2px dashed var(--lighten-20-primary-color);
}

.-badge.-done {
    background: var(--success-color) !important;
}

.sensei {
    .hair{ fill: var(--black-color) }
    .eyes { fill: var(--primary-color); }
    .beard { fill: var(--darken-40-secondary-color); }
}

.-favorite {
    &:hover, &.-bookmarked {
        color: var(--highlight) !important;
    }
}

.text-red {
    color: var(--error-color);
    &:hover, &:active {
        color: var(--error-color);
    }
}

.-remove { font-size: .7rem; }
.view-content .content-body .-right .content-list .-content i.-dragger {
    margin-bottom: .1rem
}
