.view-student-school-contact {
    padding: .5rem 0px;
    padding-bottom: 3rem;
    h2 {
        font-weight: normal;
        font-size: 1.5rem;
        padding: 0px;
        margin: 0px;
        border-bottom: 2px solid var(--lighten-22-secondary-color);
    }

    p.-description {
        padding-top: .5rem;
        color: var(--inverse-color);
    }

    .school-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        grid-auto-rows: auto;
        grid-gap: 2rem;
        grid-column: 1;
        margin-top: 0.5rem;
        margin-bottom: 2rem;

        > div {
            padding: 0px 2rem;
            border-radius: 1rem;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            cursor: pointer;

            @include box-shadow(
            10px 10px 10px var(--shadow-secondary-color),
            -5px -5px 10px var(--shadow-secondary-color));

            &.-school {
                position: relative;
                height: 20rem;
                width: 25rem;
                transition: all .5s ease;

                h4 {
                    font-weight: normal;
                    margin: .1rem;
                    text-align: center;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    white-space: nowrap;
                }

                p {
                    width: 100%;
                    text-align: left;
                    margin-bottom: .5rem;
                    font-size: 1rem;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    i {
                        font-size: 1.3rem;
                        margin-right: .2rem;
                        color: var(--primary-color);
                    }
                }

                @include box-shadow(
                    10px 10px 10px var(--shadow-inverse-color),
                    -5px -5px 10px var(--shadow-inverse-color));

                &:hover {
                    @include box-shadow(
                    10px 10px 10px var(--shadow-2-highlight),
                    -5px -5px 10px var(--shadow-2-highlight));
                }
            }
        }
    }

    .-logo-wrapper {
        max-width: 10rem;
        width: 100%;
    }
}
