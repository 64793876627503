.view-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    > header {
        padding: 3rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primary-color);

        ul.-menu {
            list-style: none;
            margin: 0;
            padding: 0;

            display: flex;

            li {
                padding: 0px .4rem;
                position: relative;

                &:hover {
                    ul.-dropdown {
                        display: block;
                    }
                }

                a {
                    transition: color .7s ease;
                    color: var(--lighten-10-inverse-color);

                    &:hover {
                        color: var(--darken-10-inverse-color);

                        i.-badge:after {
                            background: var(--error-color);
                        }
                    }

                    i {
                        font-size: 2rem;
                        padding: 3px;
                    }

                    i.-avatar {
                        transition: color .5s ease;
                        border-radius: .2rem;

                        background: var(--lighten-22-inverse-color);
                        color: var(--primary-color);
                    }

                    i.-badge {
                        position: relative;

                        &:after{
                            content: "1";
                            position: absolute;
                            left: .4rem;
                            top: .4rem;
                            height: .5rem;
                            width: .5rem;
                            text-align: center;
                            border-radius: 50%;
                            background: var(--lighten-10-error);
                        }
                    }
                }
            }
        }
    }

    .content-body {
        display: grid;

        &.-modules-hidden {
            .title-wrapper {
                > .-buttons {
                    margin-right: 3rem;
                }
            }
        }

        .title-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > .-buttons {
                margin-top: 0px;
                display: flex;
                flex-direction: row;

                > button {
                    margin-left: .5rem;
                    -webkit-appearance: none;
                    display: inline-block;
                    padding: 0.25rem .75rem;
                    vertical-align: middle;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: none;
                    line-height: 1;
                    font-weight: normal !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    border: $button-border-width $button-border-style $button-color;
                    border-radius: $button-border-radius;
                    background: lighten($button-color, 30);
                    color: var(--secondary-color);
                    font-weight: $button-font-weight;
                    font-family: $button-font-family;
                    font-size: $button-font-size;
                    text-transform: $button-text-transform;

                    i {
                        margin-top: 0px;
                    }

                    &.-complete {
                        border-color: var(--success-color);
                        color: var(--success-color);
                    }

                    &.-completed {
                        border-color: var(--success-color);
                        color: var(--white-color);
                        background-color: var(--success-color);
                    }

                    &:hover {
                        @include box-shadow(
                        5px 5px 5px var(--shadow-2-highlight),
                        -2px -2px 5px var(--shadow-2-highlight));

                    }
                }

            }
        }

        grid-template-columns: 9fr auto;
        grid-template-areas: 'left right';
        grid-template-rows: calc(100vh - 4.5rem);

        h4 {
            font-size: 1.3rem;
            font-weight: normal;
            padding: 0px;
            margin: 0px;
        }

        .-left {
            grid-area: left;
            overflow: auto;

            max-height: calc(100vh - 50px);

            &.-iphone {
                max-height: calc(100vh - 130px) !important;
            }

            .-content {
                width: 100%;
                min-height: 20rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                background-image: radial-gradient(
                    var(--lighten-10-primary-color),
                    var(--primary-color),
                    var(--darken-10-primary-color));

                @include box-shadow(
                0px 5px 5px var(--shadow-3-inverse-color),
                0px -5px 5px var(--shadow-3-inverse-color));

                .field-wrapper {
                    label {
                        color: var(--white-color);
                    }
                    i {
                        color: var(--white-color);
                    }
                }

                i.-open {
                    color: var(--white-color);
                }

                .field-wrapper {
                    width: 50%;
                    min-width: 18rem;
                    max-width: 30rem;
                }

                position: relative;

                i.-open {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    font-size: 2rem;
                    cursor: pointer;
                }

                &.-video {
                    min-height: 15rem;
                    padding: 2rem;
                    .field-wrapper {
                        max-width: 90%;
                        width: 100%;
                        text-align: left;
                        margin-bottom: 1rem;
                        float: left;
                        label {
                            display: none;
                        }
                    }
                }
            }

            .-description {
                padding: 1rem 2rem;
                padding-bottom: 5rem;
                overflow: initial;

                h4 {
                    font-size: 1.5rem;
                    font-weight: normal;
                }

                p {
                    font-size: 1rem;
                }
            }
        }

        .-right {
            min-width: 19rem;
            max-width: 19rem;
            grid-area: right;

            position: relative;

            z-index: 11;
            overflow: auto;
            background: var(--background-color);

            .-header {
                padding: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                i {
                    font-size: 1.5rem;
                    cursor: pointer;
                    color: var(--primary-color);
                }

                .-remove {
                    i { font-size: 1.2rem !important; }
                }
            }

            .modules {
                overflow: auto;
                margin: 0px;
                padding: 0px;
                height: calc(100% - 4rem);

                .-module {
                    header {
                        position: sticky;
                        top: 0px;
                        padding: .5rem;
                        font-size: 1.2rem;
                        display: flex;

                        background-color: var(--lighten-15-inverse-color);
                        color: var(--primary-color);

                        span {
                            flex: 1;
                        }

                        i {
                            font-size: 1.5rem;
                            cursor: pointer;
                            margin-right: .5rem;
                            color: var(--primary-color);

                            &.-dragger { color: var(--inverse-color); }
                        }
                    }
                }

                .add-module-wrapper, .add-content-wrapper {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
                .add-content-wrapper {
                    padding-top: .5rem;
                }

                .add-module, .add-content {
                    margin-bottom: 1rem;

                    color: var(--inverse-color);
                    border: 2px dashed var(--secondary-color);

                    &:hover, &:active {
                        border: 2px dashed var(--highlight);
                        background: var(--lighten-40-highlight) !important;
                        color: var(--font-color) !important;
                    }

                    &.-remove {
                        border-color: var(--error-color) !important;
                    }
                }

                .add-module, .add-content {
                    cursor: pointer;
                    width: 100%;
                    transition: all .5s ease;

                    background: none;
                    text-align: center;
                    padding: 1rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border-radius: .5rem;

                    i {
                        margin-right: .5rem;
                    }

                    &.-remove {
                        transition: all 0s ease !important;
                    }
                }
            }

            .content-list {
                .-content {
                    background-color: var(--shadow-inverse-color);
                    border-right: 3px solid var(--lighten-10-inverse-color);

                    display: flex;
                    flex-direction: row;
                    padding: .7rem 1rem;
                    justify-content: center;
                    align-items: center;

                    &.-active {
                        border-right: 3px solid var(--highlight);
                        background: var(--lighten-30-highlight);

                        background-image: linear-gradient(
                        to right,
                        var(--lighten-42-highlight),
                        var(--lighten-45-highlight),
                        var(--lighten-48-highlight));
                        a {
                            color: var(--darken-30-highlight);
                        }
                    }

                    a {
                        flex: 1;
                        text-align: left;
                        font-size: 1rem;
                    }
                    span {
                        margin-left: .5rem;
                        margin-top: .2rem;
                        font-size: .9rem;
                        color: var(--inverse-color);
                    }

                    i {
                        font-size: 1.1rem;
                        margin-left: .7rem;
                        color: var(--inverse-color);

                        &.-done {
                            color: var(--success-color);
                        }
                        &.-not-done {
                            color: var(--lighten-15-inverse-color);
                        }
                    }

                    .-remove {
                        i {
                            margin-top: .2rem;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
    }

    .-buttons {
        margin-top: 3rem;
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                margin-top: -.2rem;
            }
        }
    }

    .add-file, .add-external_link {
        border-radius: 1rem;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .5s ease;

        height: 15rem;

        text-align: center;

        color: var(--inverse-color);
        border: 2px dashed var(--secondary-color);

        @include box-shadow(
        10px 10px 10px var(--shadow-2-inverse-color),
        -5px -5px 10px var(--shadow-2-inverse-color));

        &:hover, &:active {
            border: 2px dashed var(--highlight);
            background: var(--lighten-40-highlight) !important;
            color: var(--font-color) !important;
        }

        cursor: pointer;
        i {
            font-size: 2rem;
        }
        p {
            font-size: 1.3rem;
        }

        &.-remove {
            transition: all 0s ease !important;
            border-color: var(--error-color) !important;
            transition: all 0s ease !important;
        }
    }

    .add-external_link {
        height: 4rem;
        flex-direction: row;
        border-radius: .5rem;
        margin-bottom: 1.5rem;
        i {
            margin-right: 1rem;
        }
    }

    .external_links-list {
        margin-top: .5rem;
    }

    .files-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
        grid-auto-rows: auto;
        grid-gap: 1.2rem;
        grid-column: 1;
        margin-top: .5rem;

        @keyframes spin {
            from { transform:rotate(0deg); }
            to { transform:rotate(360deg); }
        }

        .-loading {
            i {
                font-size: 3rem;

                animation-name: spin;
                animation-duration: 1000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

                color: var(--inverse-color);
            }
        }

        .-file {
            cursor: grab;
            height: 15rem;

            border-radius: 1rem;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            position: relative;
            padding: 0px 2rem;

            @include box-shadow(
            10px 10px 10px var(--shadow-inverse-color),
            -5px -5px 10px var(--shadow-inverse-color));

            &:hover {
                @include box-shadow(
                10px 10px 10px var(--shadow-2-highlight),
                -5px -5px 10px var(--shadow-2-highlight));
            }

            .-published {
                position: absolute;
                top: 1rem;
                left: 1rem;
                border-radius: .5rem;
                z-index: 5;
                cursor: pointer;

                i {
                    font-size: 1.5rem;
                }
            }

            h5 {
                font-size: 1.25rem;
                font-weight: normal;
                cursor: text !important;
                overflow-wrap: break-word;
                width: 100%;
            }

            .-draft {
                position: absolute;
                top: 1rem;
                right: 1rem;
                border-radius: .5rem;
                z-index: 5;
                cursor: pointer;
                color: var(--inverse-color);

                i {
                    font-size: 1.5rem;
                }
            }

            p {
                z-index: 5;
                width: 100%;
                text-align: left;
                margin-bottom: .5rem;
                font-size: 1rem;
                cursor: pointer;
                color: var(--white-color);

                i {
                    font-size: 1.3rem;
                    margin-right: .2rem;
                    color: var(--white-color);
                }
            }

            h4 {
                font-weight: normal;
                font-size: 1.5rem;
                z-index: 5;
                border-bottom: 0px;
                text-align: center;
                cursor: pointer;
                color: var(--white-color);
            }

            .-background {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                z-index: 3;

                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;

                .overlay {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    opacity: .9;

                    background: var(--primary-color);
                    background: linear-gradient(180deg,
                    var(--primary-color) 0%,
                    var(--primary-color) 35%,
                    var(--darken-15-primary-color) 100%);
                }

                border-radius: .5rem;

                img {
                    object-fit: cover;
                    width: 100%;
                    object-position: center;
                    height: 20rem;
                }
            }
        }
    }

    .external-link {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        align-items: center;
        justify-content: center;

        .-dragger {
            color: var(--secondary-color);
        }

        .field-wrapper { flex: 1 }
        .field-wrapper {
            margin-right: 1rem;
            .field {
                margin-bottom: 0px;
            }
        }
    }

    &.-student {
        .-header {
            padding-bottom: 1rem;
        }

        .-file {
            cursor: pointer;
            h5 { cursor: pointer !important;}
        }

        .external-link {
            padding: .5rem;
            font-size: 1.2rem;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;

            background: var(--lighten-21-inverse-color);
            color: var(--black-color);
            border-radius: .3rem;

            &:hover {
                @include box-shadow(
                5px 5px 5px var(--shadow-2-highlight),
                0px 0px 0px var(--shadow-2-highlight));
            }

            i {
                margin-right: .5rem;
                margin-top: -.2rem;
                color: var(--primary-color);
            }

            a {
                color: var(--darken-40-inverse-color);
            }
        }
    }
}
