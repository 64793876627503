.clearfix::before,
.clearfix::after {
    content: ' ';
    display: block;
}

.clearfix:after {
    clear: both;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.padding-top {
  padding-top: 2rem;
}

.padding-bottom {
  padding-bottom: 2rem;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.margin-top {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 2rem;
}

.space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}