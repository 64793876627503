.view-gateway-list, .base-view {
    padding: .5rem 0px;
    padding-bottom: 3rem;
    h2 {
        font-weight: normal;
        font-size: 1.5rem;
        padding: 0px;
        margin: 0px;

        color: var(--darken-20-inverse-color);
        border-bottom: 2px solid var(--lighten-22-secondary-color);
    }

    h3 {
        font-weight: normal;
        font-size: 1.2rem;
        padding: 0px;
        margin: 0px;
    }

    p.-description {
        padding-top: .5rem;
        color: var(--inverse-color);
    }

    .gateway-list-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        grid-auto-rows: auto;
        grid-gap: 1.2rem;
        grid-column: 1;
        margin-top: 2rem;

        > div {
            padding: 0px 2rem;
            border-radius: 1rem;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            position: relative;

            &.-gateway {
                cursor: pointer;

                .-add-integration {
                    margin-top: 1rem;
                }

                .-remove {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    cursor: pointer;
                    color: var(--secondary-color);
                }

                p {
                    width: 100%;
                    text-align: left;
                    margin-bottom: .5rem;
                    font-size: 1rem;

                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    i {
                        font-size: 1.3rem;
                        margin-right: .2rem;
                        color: var(--primary-color);
                    }
                }

                @include box-shadow(
                    10px 10px 10px var(--shadow-inverse-color),
                    -5px -5px 10px var(--shadow-inverse-color));

                &:hover {
                    @include box-shadow(
                    10px 10px 10px var(--shadow-2-highlight),
                    -5px -5px 10px var(--shadow-2-highlight));
                }

                h4 {
                    font-weight: normal;
                    margin-bottom: 2rem;
                    text-align: center;
                }

                height: 20rem;
                transition: all .5s ease;
            }

            &.-add {
                border-radius: 1rem;
                text-align: center;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all .5s ease;

                height: 20rem;
                cursor: pointer;

                color: var(--inverse-color);
                border: 2px dashed var(--secondary-color);

                @include box-shadow(
                10px 10px 10px var(--shadow-2-secondary-color),
                -5px -5px 10px var(--shadow-2-secondary-color));

                &:hover, &:active {
                    border: 2px dashed var(--highlight);
                    background: var(--lighten-40-highlight) !important;
                    color: var(--font-color) !important;
                }
                i {
                    font-size: 2rem;
                }
                p {
                    font-size: 1.3rem;
                }
            }

            @include box-shadow(
            10px 10px 10px var(--shadow-secondary-color),
            -5px -5px 10px var(--shadow-secondary-color));
        }
    }
}
