.school-template.-school {
    .-label { margin-bottom: 1rem; }

    p {
        display: flex;
        align-items: center;

        i {
            margin-right: .5rem !important;
        }
    }
}
.view-school-list {
    padding: .5rem 0px;
    padding-bottom: 3rem;
    h2 {
        font-weight: normal;
        font-size: 1.5rem;
        padding: 0px;
        margin: 0px;
        border-bottom: 2px solid var(--lighten-22-secondary-color);
    }

    p.-description {
        padding-top: .5rem;
        color: var(--inverse-color);
    }

    .school-list-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        grid-auto-rows: auto;
        grid-gap: 2rem;
        grid-column: 1;
        margin-top: 2rem;
        margin-bottom: 2rem;

        > div {
            padding: 0px 2rem;
            border-radius: 1rem;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            cursor: pointer;

            @include box-shadow(
            10px 10px 10px var(--shadow-secondary-color),
            -5px -5px 10px var(--shadow-secondary-color));

            &.-school {
                position: relative;

                p {
                    width: 100%;
                    text-align: left;
                    margin-bottom: .5rem;
                    font-size: 1rem;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    i {
                        font-size: 1.3rem;
                        margin-right: .2rem;
                        color: var(--primary-color);
                    }
                }

                h4 {
                    font-weight: normal;
                    margin: .1rem;
                    text-align: center;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    white-space: nowrap;
                }

                height: 20rem;
                transition: all .5s ease;

                @include box-shadow(
                    10px 10px 10px var(--shadow-inverse-color),
                    -5px -5px 10px var(--shadow-inverse-color));

                &:hover {
                    @include box-shadow(
                    10px 10px 10px var(--shadow-2-highlight),
                    -5px -5px 10px var(--shadow-2-highlight));
                }
            }

            &.-add {
                border-radius: 1rem;
                text-align: center;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all .5s ease;

                height: 20rem;
                cursor: pointer;

                color: var(--inverse-color);
                border: 2px dashed var(--secondary-color);

                @include box-shadow(
                10px 10px 10px var(--shadow-2-secondary-color),
                -5px -5px 10px var(--shadow-2-secondary-color));

                &:hover, &:active {
                    border: 2px dashed var(--highlight);
                    background: var(--lighten-40-highlight) !important;
                    color: var(--font-color) !important;
                }
                i {
                    font-size: 2rem;
                }
                p {
                    font-size: 1.3rem;
                }
            }
        }
    }

    .-logo-wrapper {
        max-width: 10rem;
        width: 100%;
    }


    .grid-wrapper .grid-filter form .field-wrapper {
        margin-right: .5rem;
    }
}
