.view-student-list {
    .searchbar {
        margin-top: 1rem;

        input {
            padding: 1rem;
            border-radius: .5rem;
            width: 100%;
            max-width: 400px;

            border: 1px solid var(--lighten-10-inverse-color);

            @include box-shadow(
            5px 5px 5px var(--shadow-inverse-color),
            -2px -2px 5px var(--shadow-inverse-color));
        }
    }

    main.student-list {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));;
        grid-gap: 1.5rem;

        .-student {
            padding: 1rem;
            margin-top: 2rem;

            @include box-shadow(
                10px 10px 10px var(--shadow-inverse-color),
                -5px -5px 10px var(--shadow-inverse-color));

            &:hover {
                @include box-shadow(
                10px 10px 10px var(--shadow-2-highlight),
                -5px -5px 10px var(--shadow-2-highlight));
            }

            h4 { color: var(--darken-20-inverse-color); font-weight: normal; }

            header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .-badge {
                    padding: .1rem .5rem;
                    border-radius: .2rem;
                    font-size: 1rem;

                    background-color: var(--success-color);
                    color: var(--white-color);
                    text-shadow: 1px 1px rgba(var(--darken-10-success-color), .4);
                }

                .-edit {
                    padding: .2rem;
                    border-radius: .3rem;

                    cursor: pointer;

                    height: 1.7rem;
                    width: 1.7rem;

                    background-color: var(--lighten-15-inverse-color);

                    i {
                        font-size: 1.3rem;
                        color: var(--white-color);
                    }
                }

                .-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    margin-top: -3rem;
                    border-radius: 4rem;
                    width: 4rem;
                    height: 4rem;
                    overflow: hidden;

                    background-color: var(--primary-color);

                    i {
                        font-size: 4.5rem;
                        margin-bottom: -1rem;

                        color: var(--lighten-25-inverse-color);
                    }
                }
            }

            main {
                text-align: center;
                margin-top: 1rem;

                h4 {
                    margin: 0px;
                    padding: 0px;
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .gateway-list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    > div {
                        margin: .5rem;
                        padding: .1rem .5rem;
                        border-radius: .5rem;

                        background-color: var(--lighten-10-inverse-color);
                        color: var(--white-color);
                    }

                    margin-bottom: .5rem;
                    margin-top: .5rem;
                }

                p {
                    padding: 0px;
                    color: var(--darken-10-inverse-color);
                }
            }
        }
    }
}
