%forms {
    border: $form-border;
    border-radius: $border-radius;

    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}

%forms-hover {
    border: $form-border-hover;
}

%forms-focus {
    border: $form-border-focus;
}

#{$forms} {
    @extend %forms;

    &:hover, &.active {
        @extend %forms-hover;
    }

    &:focus,
    &:active {
        @extend %forms-focus;
    }
}

textarea {
    overflow: auto;
    height: auto;
}

fieldset {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: $padding;
    margin: $margins 0;
}

legend {
    padding: 0 0.5rem;
    font-weight: 600;
}

select {
    width: 100%;
    border-color: var(--lighten-20-secondary-color);
    color: var(--font-color);
    line-height: 1;
    padding: .5rem;
}

select::-ms-expand {
    display: none;
}

[type='range'] {
    width: 100%;
}

label {
    font-weight: 600;
    max-width: 100%;
    display: block;
    margin: 1rem 0 0.5rem;
}

@include small-breakpoint {
    .split-form {
        label {
            text-align: right;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
        }
    }
}

input,
select,
textarea {
    background: var(--white-color);
    border-color: var(--lighten-background-color) !important;
    color: var(--black-color);

    &.has-error {
        border: 1px solid var(--error-color);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px var(--lighten-10-error);
    }

    &.is-success {
        border: 1px solid var(--success-color);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px var(--lighten-25-success-color);
    }

    &:hover,
    &:focus,
    &:active {
        &.has-error {
            @extend .has-error;
        }

        &.is-success {
            @extend .is-success;
        }
    }
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: $placeholder;
}

form {
    .-buttons {
        &.-right {
            text-align: right;

            button.btn {
                margin-left: 1rem;
            }
        }
    }

    .section {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        margin-top: .5rem;

        .-description {
            flex: .4;
            padding: 0px 5rem;
            text-align: right;
        }

        .-fields {
            flex: 1;
            padding: 2rem;
            border-radius: 1rem;

            @include box-shadow(
            10px 10px 25px var(--shadow-inverse-color),
            -5px -5px 25px var(--shadow-inverse-color));
        }

        p {
            font-size: 1rem;
            color: var(--darken-10-inverse-color);
        }

        h2 {
            font-size: 1.5rem;
            font-weight: normal;
            margin: 0px;
            padding: 0px;
        }

    }

}

form.-force-update {
    .-buttons {
        &.-right {
            text-align: right;

            button.btn {
                margin-left: 1rem;
            }
        }
    }

    .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .-description {
            flex: .6;
            padding: 20px 25px;
            text-align: left;

        }

        .-fields {
            flex: 1;
            padding: 2rem;
            border-radius: 1rem;

            @include box-shadow(
            10px 10px 25px var(--shadow-inverse-color),
            -5px -5px 25px var(--shadow-inverse-color));
        }

        p {
            font-size: 1rem;
            color: var(--darken-10-inverse-color);
        }

        h2 {
            font-size: 1.5rem;
            font-weight: normal;
            margin: 0px;
            padding: 0px;
        }

    }

}

.field-wrapper {
    &.-switch {
        margin-bottom: .6rem;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    &.-switch > label {
        display: flex;
        flex-direction: row;
        align-items: center;

        > span {
            margin-left: 1rem;
        }
    }
    label {
        font-weight: normal;
        margin: 0px;
        padding: 0px;
        color: var(--inverse-color);
    }
    .field {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        border-radius: 5px;
        border: 2px solid var(--lighten-20-secondary-color);
        margin-bottom: .6rem;
        flex-flow: wrap;

        .input-error {
            width: 100%;
            padding: .3rem .8rem;

            break-before: always;
            background-color: var(--shadow-error);
            color: var(--font-color);
        }

        &:hover {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
            0 0 6px var(--lighten-5-link-color);
        }
        &.-active {
            border: 2px solid var(--link-color);
        }
        &.-invalid {
            border: 2px solid var(--lighten-30-error);
            color: var(--darken-10-error);
            i, .icon {
                color: var(--error-color);
            }
        }

        .icon {
            color: var(--primary-color);
            display: flex;
            width: 50px;
            align-items: center;
            justify-content: center;

            &.-clickable {
                cursor: pointer;
            }

            i {
                font-size: 1.5rem;
                width: 22px;
            }
        }

        .input {
            flex: 1;
            input {
                border: 0px;
                padding: 15px;
                width: 100%;
            }
        }
    }
}


.input-switch {
    display: flex;
    flex-direction: row;

    input {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 50px;
        width: 50px;
        height: 25px;

        background: var(--secondary-color);
        position: relative;
        transition: background-color .2s;

        &:active span {
            width: 30px;
        }

        &.-active {
            background-color: var(--primary-color);
        }
    }

    span {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 21px;
        height: 21px;
        border-radius: 21px;
        transition: 0.2s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    input:checked + label span {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

    &.-min {

        label {
            border-radius: 25px;
            width: 25px;
            height: 12.5px;
        }

        span {
            top: 1px;
            left: 1px;
            width: 10px;
            height: 10px;
            border-radius: 10.5px;
        }

        &:active span {
            width: 15px;
        }
    }
}
